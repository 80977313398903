export const EsocialEventoTipoEnum = Object.freeze({
    S2210: 'S-2210',
    S2220: 'S-2220',
    S2240: 'S-2240',
    S3000: 'S-3000'
});

export const EsocialEventoTipoEnumEnumLabel = new Map([
    [EsocialEventoTipoEnum.S2210, 'S-2210 - Comunicação de Acidente de Trabalho'],
    [EsocialEventoTipoEnum.S2220, 'S-2220 - Monitoramento da Saúde do Trabalhador'],
    [EsocialEventoTipoEnum.S2240, 'S-2240 - Condições Ambientais do Trabalho - Agentes Nocivos'],
    [EsocialEventoTipoEnum.S3000, 'S-3000 - Exclusão de Eventos']
]);
