<template>
    <Dialog :draggable="false" :modal="true" :style="{width: '600px'}"
            :visible="showModal" header="Cadastrando ambiente" position="top">
        <form id="formAmbiente" ref="form">
            <div class="p-fluid grid">
                <div class="field col-4 mb-0">
                    <label :class="{'p-error':v$.form.tipoAmbiente.$invalid && submitted}" for="tipoAmbiente">
                        Tipo de ambiente <span class="p-error">*</span>
                    </label>
                    <Dropdown id="tipoAmbiente" v-model="form.tipoAmbiente"
                              :class="{'p-invalid':v$.form.tipoAmbiente.$invalid && submitted}" :options="ambienteTipos"
                              :showClear="true" optionLabel="name" optionValue="id"
                    />
                    <small v-if="v$.form.tipoAmbiente.$invalid && submitted"
                           class="p-error">{{ v$.form.tipoAmbiente.required.$message }}</small>
                </div>
                <div class="field col-4 m-0">
                    <label :class="{'p-error':v$.form.tipoInscricao.$invalid && submitted}" for="tipoInscricao">
                        Tipo de inscrição <span class="p-error">*</span>
                    </label>
                    <Dropdown id="tipoInscricao" v-model="form.tipoInscricao"
                              :class="{'p-invalid':v$.form.tipoInscricao.$invalid && submitted}"
                              :options="inscricaoTipos" :showClear="true" optionLabel="name" optionValue="id"
                    />
                    <small v-if="v$.form.tipoInscricao.$invalid && submitted"
                           class="p-error">{{ v$.form.tipoInscricao.required.$message }}</small>
                </div>
                <div class="field col-4 m-0">
                    <label :class="{'p-error':v$.form.numeroInscricao.$invalid && submitted}" for="numeroInscricao">
                        Número da inscrição <span class="p-error">*</span>
                    </label>
                    <InputText id="inscricao" v-model="form.numeroInscricao"
                               :class="{'p-invalid':v$.form.numeroInscricao.$invalid && submitted}"/>
                    <small v-if="v$.form.numeroInscricao.$invalid && submitted"
                           class="p-error">{{ v$.form.numeroInscricao.required.$message }}</small>
                </div>
                <div class="field col-12 m-0">
                    <label :class="{'p-error':v$.form.descricaoAmbiente.$invalid && submitted}" for="descricaoAmbiente">
                        Descrição do ambiente <span class="p-error">*</span>
                    </label>
                    <InputText id="descricaoAmbiente" v-model="form.descricaoAmbiente"
                               :class="{'p-invalid':v$.form.descricaoAmbiente.$invalid && submitted}"/>
                    <small v-if="v$.form.descricaoAmbiente.$invalid && submitted"
                           class="p-error">{{ v$.form.descricaoAmbiente.required.$message }}</small>
                </div>
            </div>
        </form>
        <template #footer>
            <Button class="p-button-text" icon="pi pi-times" label="Cancelar" @click="toggleModal"/>
            <Button :label="isEdit ? 'Atualizar' : 'Adicionar'" icon="pi pi-check" @click="save"/>
        </template>
    </Dialog>

    <Divider align="left" type="dashed">
        <b>Informações relativas ao ambiente de trabalho</b>
    </Divider>
    <div class="field col-12 m-0">
        <Button class="p-button-outlined w-14rem" icon="pi pi-plus" label="Adicionar ambiente"
                @click="toggleModal"></Button>
    </div>

    <DataTable v-if="ambientes.length" :value="ambientes" class="w-full border-1 border-gray-100">
        <Column :style="{'width':'31%'}" header="Tipo de ambiente">
            <template #body="slotProps">
                {{ this.ambienteTipos.find(element => element.id === slotProps.data.tipoAmbiente)?.name }}
            </template>
        </Column>
        <Column :style="{'width':'15%'}" header="Tipo de inscrição">
            <template #body="slotProps">
                {{ this.inscricaoTipos.find(element => element.id === slotProps.data.tipoInscricao)?.name }}
            </template>
        </Column>
        <Column :style="{'width':'54%'}" field="descricaoAmbiente" header="Descrição"></Column>
        <Column bodyClass="text-right" class="p-2">
            <template #body="slotProps">
                <div class="inline-flex">
                    <Button class="p-button-text p-button-secondary inline-flex mr-2" icon="pi pi-pencil"
                            title="Editar" @click="edit(slotProps.index, slotProps.data)"/>
                    <Button class="p-button-text p-button-secondary" icon="pi pi-times" title="Excluir"
                            @click="remove(slotProps.index)"/>
                </div>
            </template>
        </Column>
    </DataTable>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';

export default {
    setup: () => ({v$: useVuelidate()}),
    props: {
        ambientes: Array,
        ambienteCreate: Function,
        ambienteRemove: Function,
        ambienteUpdate: Function
    },
    data() {
        return {
            recordIndex: undefined,
            showModal: false,
            submitted: false,
            form: {},
            ambienteTipos: [
                {id: 1, name: 'Estabelecimento do próprio empregador'},
                {id: 2, name: 'Estabelecimento de terceiros'}
            ],
            inscricaoTipos: [
                {id: '1', name: 'CNPJ'},
                {id: '3', name: 'CAEPF'},
                {id: '4', name: 'CNO'}
            ],
        };
    },
    validations() {
        return {
            form: {
                tipoAmbiente: {required: helpers.withMessage('Tipo de ambiente deve ser informado', required)},
                descricaoAmbiente: {required: helpers.withMessage('Descrição do ambiente deve ser informado', required)},
                tipoInscricao: {required: helpers.withMessage('Tipo de inscrição deve ser informado', required)},
                numeroInscricao: {required: helpers.withMessage('Número da inscrição deve ser informado', required)},
            }
        };
    },
    computed: {
        isEdit() {
            return this.recordIndex >= 0;
        }
    },
    methods: {
        toggleModal() {
            this.showModal = !this.showModal;
            this.resetForm();
        },
        resetForm() {
            this.recordIndex = undefined;
            this.submitted = false;
            this.form = {};
        },
        edit(index, data) {
            this.toggleModal();
            this.recordIndex = index;
            this.form = Object.assign({}, data);
        },
        remove(index) {
            this.ambienteRemove(index);
        },
        async save() {
            this.submitted = true;

            const result = await this.v$.$validate();
            if (!result) {
                return;
            }

            const ambiente = Object.assign({}, this.form);
            this.isEdit
                ? this.ambienteUpdate(this.recordIndex, ambiente)
                : this.ambienteCreate(ambiente);

            this.toggleModal();
        }
    }
};
</script>
