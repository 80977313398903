<template>
    <form id="form" ref="form">
        <div class="p-fluid grid">
            <Divider align="left" class="mb-0" type="dashed">
                <b>Grupo que identifica o evento objeto da exclusão</b>
            </Divider>
            <div class="field col-3 m-0">
                <label :class="{'p-error':v$.form.exclusao.tipo.$invalid && submitted}" for="exclusaoTipo">
                    Tipo do evento <span class="p-error">*</span>
                </label>
                <InputMask id="exclusaoTipo" v-model="form.exclusao.tipo"
                           :class="{'p-invalid':v$.form.exclusao.tipo.$invalid && submitted}" mask="S-9999"/>
                <small v-if="(v$.form.exclusao.tipo.$invalid && submitted)"
                       class="p-error">{{ v$.form.exclusao.tipo.required.$message }}</small>
            </div>
            <div class="field col-6 m-0">
                <label :class="{'p-error':v$.form.exclusao.recibo.$invalid && submitted}"
                       for="exclusaoRecibo">
                    Número do recibo <span class="p-error">*</span>
                </label>
                <InputText id="exclusaoRecibo" v-model="form.exclusao.recibo"
                           :class="{'p-invalid':v$.form.exclusao.recibo.$invalid && submitted}"/>
                <small v-if="(v$.form.exclusao.recibo.$invalid && submitted)"
                       class="p-error">{{ v$.form.exclusao.recibo.required.$message }}</small>
            </div>

            <Divider align="left" class="mb-0" type="dashed">
                <b>Grupo que identifica a qual período de apuração pertence o evento que será excluído</b>
            </Divider>
            <div class="field col-3 m-0">
                <label for="folhaPeriodo">Período de apuração</label>
                <Dropdown id="folhaPeriodo" v-model="form.exclusao.folha.periodo"
                          :options="selectPeriodos" :showClear="true" optionLabel="name" optionValue="id"/>
            </div>
            <div class="field col-3 m-0">
                <label for="folhaReferencia">Referência (ano/mês)</label>
                <InputMask id="folhaReferencia" v-model="form.exclusao.folha.referencia" mask="9999-99"
                           slotChar="AAAA-MM"/>
            </div>

            <Toolbar class="w-full">
                <template #start>
                    <Button :loading="isLoading" class="p-button w-8rem p-button-outlined" icon="pi pi-times"
                            label="Cancelar" @click="$router.go(-1)"></Button>
                </template>

                <template #end>
                    <Button :loading="isLoading" class="p-button w-8rem" icon="pi pi-check" label="Salvar"
                            @click="save"/>
                </template>
            </Toolbar>
        </div>
    </form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import BaseService from '@/services/BaseService';
import { showError, showSuccess } from '../../../../../utils/Toast';

export default {
    setup: () => ({ v$: useVuelidate() }),
    props: [
        'customerId',
        'employeeId',
        'recordId',
        'recordJson',
        'removeEmptyValues'
    ],
    data() {
        return {
            submitted: false,
            isLoading: false,
            form: {
                exclusao: {
                    folha: {}
                },
            },
            selectPeriodos: [
                { id: '1', name: 'Mensal' },
                { id: '2', name: 'Anual (13° salário)' }
            ]
        };
    },
    validations() {
        return {
            form: {
                exclusao: {
                    tipo: { required: helpers.withMessage('Tipo do evento deve ser informado', required) },
                    recibo: { required: helpers.withMessage('Número do recibo deve ser informado', required) },
                }
            }
        };
    },
    mounted() {
        this.$service = new BaseService('esocial/clientes/:customerId/funcionarios/:employeeId/eventos/S3000');

        if (this.recordId) {
            this.form = this.recordJson;
            this.form.id = this.recordId;

            if (!this.form.exclusao.folha) {
                this.form.exclusao.folha = {};
            }
        }
    },
    methods: {
        async save() {
            this.submitted = true;

            const result = await this.v$.form.$validate();
            if (!result) {
                return;
            }

            this.$service.path = this.$service.path.replace(':customerId', this.customerId);
            this.$service.path = this.$service.path.replace(':employeeId', this.employeeId);

            const form = this.removeEmptyValues(JSON.parse(JSON.stringify(this.form)));

            this.isLoading = true;
            try {
                await this.$service.save(form);
                showSuccess(this.$toast, (!this.form.id ? 'Registro criado' : 'Registro atualizado'));
                await this.$router.push('/esocial/list');
            } catch (error) {
                showError(this.$toast, error);
            } finally {
                this.submitted = false;
                this.isLoading = false;
            }
        }
    }
};
</script>
