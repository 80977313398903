<template>
    <div class="pt-5">
        <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
            <li>
                <a class="text-500 no-underline line-height-3">eSocial</a>
            </li>
            <li class="px-2">
                <i class="pi pi-angle-right text-500 line-height-3"></i>
            </li>
            <li>
                <a class="text-500 no-underline line-height-3 cursor-pointer"
                   @click="this.$router.push('/esocial/list')">Eventos</a>
            </li>
            <li class="px-2">
                <i class="pi pi-angle-right text-500 line-height-3"></i>
            </li>
            <li>
                <span class="text-900 line-height-3">{{ this.title }}</span>
            </li>
        </ul>
    </div>
    <Card class="mt-4 mb-6 no-padding-bottom">
        <template #content>
            <div class="p-fluid grid">
                <div class="field col-4">
                    <label for="txtEventType">Tipo de evento</label>
                    <Dropdown id="txtEventType" v-model="selectedTipoEvento" :disabled="!!this.recordId"
                              :options="tiposEventos" optionLabel="name" optionValue="id"
                              placeholder="Selecione o tipo de evento"/>
                </div>
                <div class="field col-4">
                    <label for="customerId">
                        Cliente <span class="p-error">*</span>
                    </label>
                    <DropdownCliente id="customerId" v-model="selectedCustomer" :disabled="!!this.recordId"
                                     :idValue="selectedCustomer?.id"/>
                </div>
                <div class="field col-4">
                    <label for="funcionarioId">
                        Funcionário <span class="p-error">*</span>
                    </label>
                    <DropdownEsocialFuncionario id="funcionarioId" v-model="selectedEmployee"
                                                :autoLoad="false"
                                                :customerId="selectedCustomer?.id"
                                                :disabled="!!this.recordId || !selectedCustomer"
                                                :idValue="selectedEmployee?.id"/>
                </div>
            </div>

            <FormEvent2210
                v-if="selectedTipoEvento === EsocialEventoTipoEnum.S2210 && selectedCustomer && selectedEmployee"
                :customerId="selectedCustomer?.id" :employeeId="selectedEmployee?.id"
                :recordId="this.recordId" :recordJson="this.recordJson"
                :removeEmptyValues="removeEmptyValues"/>
            <FormEvent2220
                v-if="selectedTipoEvento === EsocialEventoTipoEnum.S2220 && selectedCustomer && selectedEmployee"
                :customerId="selectedCustomer?.id" :employeeId="selectedEmployee?.id"
                :recordId="this.recordId" :recordJson="this.recordJson"
                :removeEmptyValues="removeEmptyValues"/>
            <FormEvent2240
                v-if="selectedTipoEvento === EsocialEventoTipoEnum.S2240 && selectedCustomer && selectedEmployee"
                :customerId="selectedCustomer?.id" :employeeId="selectedEmployee?.id"
                :recordId="this.recordId" :recordJson="this.recordJson"
                :removeEmptyValues="removeEmptyValues"/>
            <FormEvent3000
                v-if="selectedTipoEvento === EsocialEventoTipoEnum.S3000 && selectedCustomer && selectedEmployee"
                :customerId="selectedCustomer?.id" :employeeId="selectedEmployee?.id"
                :recordId="this.recordId" :recordJson="this.recordJson"
                :removeEmptyValues="removeEmptyValues"/>
        </template>
    </Card>
</template>

<script>
import DropdownCliente from '../../clientes/components/DropdownCliente';
import FormEvent2210 from './components/event-S2210/Form';
import FormEvent2220 from './components/event-S2220/Form';
import FormEvent2240 from './components/event-S2240/Form';
import FormEvent3000 from './components/event-S3000/Form';
import BaseService from '../../../services/BaseService';
import { showError } from '../../../utils/Toast';
import { EsocialEventoTipoEnum } from './enums/EsocialEventoTipoEnum';
import DropdownEsocialFuncionario from './components/_shared/DropdownEsocialFuncionario.vue';

export default {
    components: {
        DropdownEsocialFuncionario,
        DropdownCliente,
        FormEvent2210,
        FormEvent2220,
        FormEvent2240,
        FormEvent3000
    },
    data() {
        return {
            recordId: null,
            recordJson: null,
            selectedTipoEvento: undefined,
            selectedCustomer: undefined,
            selectedEmployee: undefined,
            tiposEventos: [
                { id: 'S-2210', name: 'S-2210 - Comunicação de Acidente de Trabalho' },
                { id: 'S-2220', name: 'S-2220 - Monitoramento da Saúde do Trabalhador' },
                { id: 'S-2240', name: 'S-2240 - Condições Ambientais do Trabalho - Agentes Nocivos' },
                { id: 'S-3000', name: 'S-3000 - Exclusão de Eventos' }
            ],
            retificar: false,
            EsocialEventoTipoEnum
        };
    },
    computed: {
        title() {
            return (this.$route.params.id ? (this.retificar ? 'Retificar' : 'Editar') : 'Adicionar') + ' evento';
        }
    },
    async mounted() {
        this.$service = new BaseService('/esocial/eventos');
        this.loading = true;

        if (this.$route.params.id) {
            try {
                const { data } = await this.$service.findById(this.$route.params.id);

                this.recordId = data.id;
                this.recordJson = data.eventoJson;

                this.selectedTipoEvento = data.eventoTipo;
                this.selectedCustomer = data.customer;
                this.selectedEmployee = data.trabalhador;

                this.retificar = !!data.esocialRecibo;
            } catch (error) {
                showError(this.$toast, error);
            }
        }

        this.loading = false;
    },
    methods: {
        removeEmptyValues(json) {
            Object.keys(json).forEach(key => {
                if (typeof json[key] === 'object' && json[key]) {
                    if (Object.keys(json[key]).length) {
                        this.removeEmptyValues(json[key]);
                        if (!Object.keys(json[key]).length) {
                            delete json[key];
                        }
                    } else {
                        delete json[key];
                    }
                } else if (!json[key]) {
                    delete json[key];
                }
            });
            return json;
        }
    }
};
</script>

<style lang="scss">
.p-card.no-padding-bottom > .p-card-body,
.p-card.no-padding-bottom > .p-card-body > .p-card-content {
    padding-bottom: 0 !important;
}
</style>
