<template>
    <Dialog :draggable="false" :modal="true" :style="{width: '600px'}"
            :visible="showModal" header="Cadastrando exame" position="top">
        <form id="formExame" ref="form">
            <div class="p-fluid grid">
                <div class="field col-4 mb-0">
                    <label :class="{'p-error':v$.form.dtExm.$invalid && submitted}" for="dtExm">
                        Data exame <span class="p-error">*</span></label>
                    <Calendar id="dtExm" v-model="dtExm" :class="{'p-invalid':v$.form.dtExm.$invalid && submitted}"
                              :showButtonBar="true" :showIcon="true" autocomplete="off"/>
                    <small v-if="(v$.form.dtExm.$invalid && submitted) || v$.form.dtExm.$pending.$response"
                           class="p-error">{{ v$.form.dtExm.required.$message }}</small>
                </div>
                <div class="field col-4 mb-0">
                    <label for="ordExame">Ordem</label>
                    <Dropdown id="ordExame" v-model="form.ordExame" :options="ordens" :showClear="true"
                              optionLabel="name" optionValue="id"/>
                </div>
                <div class="field col-4 mb-0">
                    <label for="indResult">Indicação</label>
                    <Dropdown id="indResult" v-model="form.indResult" :options="indicacoes" :showClear="true"
                              optionLabel="name" optionValue="id"/>
                </div>
                <div class="field col-12 mb-0">
                    <label :class="{'p-error':v$.form.procRealizado.$invalid && submitted}" for="procRealizado">
                        Procedimento <span class="p-error">*</span>
                    </label>
                    <Dropdown id="procRealizado" v-model="form.procRealizado"
                              :class="{'p-invalid':v$.form.procRealizado.$invalid && submitted}"
                              :options="procedimentos" :showClear="true"
                              optionLabel="name" optionValue="id"
                    />
                    <small
                        v-if="(v$.form.procRealizado.$invalid && submitted) || v$.form.procRealizado.$pending.$response"
                        class="p-error">{{ v$.form.procRealizado.required.$message }}</small>
                </div>
                <div class="field col-12">
                    <label for="obsProc">Observação</label>
                    <Textarea id="obsProc" v-model="form.obsProc"/>
                </div>
            </div>
        </form>
        <template #footer>
            <Button class="p-button-text" icon="pi pi-times" label="Cancelar" @click="toggleModal"/>
            <Button :label="isEdit ? 'Atualizar' : 'Adicionar'" icon="pi pi-check" @click="save"/>
        </template>
    </Dialog>

    <Divider align="left" type="dashed">
        <b>Avaliações clínicas</b>
    </Divider>
    <div class="field col-12 m-0">
        <Button class="p-button-outlined w-14rem" icon="pi pi-plus" label="Adicionar avaliação"
                @click="toggleModal"></Button>
    </div>

    <DataTable v-if="exame.length" :value="exame" class="w-full border-1 border-gray-100">
        <Column :style="{'width':'15%'}" header="Data exame">
            <template #body="slotProps">
                {{ formatDate(slotProps.data.dtExm) }}
            </template>
        </Column>
        <Column :style="{'width':'20%'}" header="Ordem exame">
            <template #body="slotProps">
                {{ this.ordens.find(element => element.id === slotProps.data.ordExame)?.name }}
            </template>
        </Column>
        <Column :style="{'width':'20%'}" header="Indicação">
            <template #body="slotProps">
                {{ this.indicacoes.find(element => element.id === slotProps.data.indResult)?.name }}
            </template>
        </Column>
        <Column :style="{'width':'45%'}" header="Código procedimento">
            <template #body="slotProps">
                {{ this.procedimentos.find(element => element.id === slotProps.data.procRealizado)?.name }}
            </template>
        </Column>
        <Column bodyClass="text-right" class="p-2">
            <template #body="slotProps">
                <div class="inline-flex">
                    <Button class="p-button-text p-button-secondary inline-flex mr-2" icon="pi pi-pencil"
                            title="Editar" @click="edit(slotProps.index, slotProps.data)"/>
                    <Button class="p-button-text p-button-secondary" icon="pi pi-times" title="Excluir"
                            @click="remove(slotProps.index)"/>
                </div>
            </template>
        </Column>
    </DataTable>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import { lightFormat } from 'date-fns';
import { mDate } from '../../../../../utils/Mask';

const tabela27Procedimentos = require('../../_tabelas-esocial/tabela-27.json');

export default {
    setup: () => ({ v$: useVuelidate() }),
    props: {
        exame: Array,
        exameCreate: Function,
        exameUpdate: Function,
        exameRemove: Function
    },
    data() {
        return {
            recordIndex: undefined,
            showModal: false,
            submitted: false,
            dtExm: undefined,
            form: {
                dtExm: undefined,
                procRealizado: undefined,
                obsProc: undefined,
                ordExame: undefined,
                indResult: undefined
            },
            ordens: [
                { id: 1, name: 'Inicial' },
                { id: 2, name: 'Sequencial' }
            ],
            indicacoes: [
                { id: 1, name: 'Normal' },
                { id: 2, name: 'Alterado' },
                { id: 3, name: 'Estável' },
                { id: 4, name: 'Agravamento' }
            ],
            procedimentos: tabela27Procedimentos
        };
    },
    validations() {
        return {
            form: {
                dtExm: {
                    required: helpers.withMessage('Data exame deve ser informado', required)
                },
                procRealizado: {
                    required: helpers.withMessage('Procedimento deve ser informado', required)
                }
            }

        };
    },
    computed: {
        isEdit() {
            return this.recordIndex >= 0;
        }
    },
    watch: {
        dtExm: function (newValue) {
            if (newValue) {
                this.form.dtExm = this.setDate(newValue);
                this.dtExm = newValue;
            }
        }
    },
    methods: {
        setDate(dateString) {
            const formatDates = lightFormat(new Date(dateString), 'yyyy-MM-dd');
            return `${formatDates}`;
        },
        formatDate(date) {
            return mDate(date);
        },
        toggleModal() {
            this.showModal = !this.showModal;
            this.resetForm();
        },
        resetForm() {
            this.recordIndex = undefined;
            this.submitted = false;
            this.form = {};
            this.dtExm = undefined;
        },
        async save() {
            this.submitted = true;

            const result = await this.v$.$validate();
            if (!result) {
                return;
            }

            const exame = Object.assign({}, this.form);
            this.isEdit
                ? this.exameUpdate(this.recordIndex, exame)
                : this.exameCreate(exame);

            this.toggleModal();
        },
        edit(index, data) {
            this.toggleModal();
            this.recordIndex = index;
            this.form = Object.assign({}, data);
            this.dtExm = new Date(`${this.form.dtExm}T00:00`);
        },
        remove(index) {
            this.exameRemove(index);
        }
    }
};
</script>
