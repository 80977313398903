<template>
    <Dialog :draggable="false" :modal="true" :style="{width: '600px'}"
            :visible="showModal" header="Cadastrando EPI" position="top">
        <form id="formEpi" ref="form">
            <div class="p-fluid grid">
                <div class="field col-12 m-0">
                    <label for="certificado">Certificado de Aprovação - CA ou documento de avaliação do EPI</label>
                    <InputText id="certificado" v-model="form.certificado"/>
                </div>
            </div>
        </form>
        <template #footer>
            <Button class="p-button-text" icon="pi pi-times" label="Cancelar" @click="toggleModal"/>
            <Button :label="isEdit ? 'Atualizar' : 'Adicionar'" icon="pi pi-check" @click="save"/>
        </template>
    </Dialog>

    <Divider align="left" type="dashed">
        <b>EPIs</b>
    </Divider>
    <div class="field col-12 m-0">
        <Button class="p-button-outlined w-14rem" icon="pi pi-plus" label="Adicionar EPI"
                @click="toggleModal"></Button>
    </div>

    <DataTable v-if="epis.length" :value="epis" class="w-full border-1 border-gray-100">
        <Column field="certificado" header="Certificado de Aprovação - CA ou documento de avaliação do EPI"></Column>
        <Column bodyClass="text-right" class="p-2">
            <template #body="slotProps">
                <div class="inline-flex">
                    <Button class="p-button-text p-button-secondary inline-flex mr-2" icon="pi pi-pencil"
                            title="Editar" @click="edit(slotProps.index, slotProps.data)"/>
                    <Button class="p-button-text p-button-secondary" icon="pi pi-times" title="Excluir"
                            @click="remove(slotProps.index)"/>
                </div>
            </template>
        </Column>
    </DataTable>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';

export default {
    setup: () => ({v$: useVuelidate()}),
    props: {
        epis: Array,
        epiCreate: Function,
        epiRemove: Function,
        epiUpdate: Function
    },
    data() {
        return {
            recordIndex: undefined,
            showModal: false
        };
    },
    computed: {
        isEdit() {
            return this.recordIndex >= 0;
        }
    },
    methods: {
        toggleModal() {
            this.showModal = !this.showModal;
            this.resetForm();
        },
        resetForm() {
            this.recordIndex = undefined;
            this.submitted = false;
            this.form = {};
        },
        edit(index, data) {
            this.toggleModal();
            this.recordIndex = index;
            this.form = Object.assign({}, data);
        },
        remove(index) {
            this.epiRemove(index);
        },
        async save() {
            this.submitted = true;

            const result = await this.v$.$validate();
            if (!result) {
                return;
            }

            const epi = Object.assign({}, this.form);
            this.isEdit
                ? this.epiUpdate(this.recordIndex, epi)
                : this.epiCreate(epi);

            this.toggleModal();
        }
    }
};
</script>
