<template>
    <form id="form" ref="form">
        <div class="p-fluid grid">
            <Divider align="left" class="mb-0" type="dashed">
                <b>Informações do exame médico ocupacional</b>
            </Divider>
            <div class="field col-12 m-0">
                <label :class="{'p-error':v$.form.exMedOcup.tpExameOcup.$invalid && submitted}"
                       for="exMedOcupTpExameOcup">
                    Tipo do exame médico ocupacional <span class="p-error">*</span>
                </label>
                <Dropdown id="exMedOcupTpExameOcup" v-model="form.exMedOcup.tpExameOcup"
                          :class="{'p-invalid':v$.form.exMedOcup.tpExameOcup.$invalid && submitted}"
                          :options="examesTipos" :showClear="true" optionLabel="name" optionValue="id"/>
                <small
                    v-if="(v$.form.exMedOcup.tpExameOcup.$invalid && submitted) || v$.form.exMedOcup.tpExameOcup.$pending.$response"
                    class="p-error">{{ v$.form.exMedOcup.tpExameOcup.required.$message }}</small>
            </div>

            <Divider align="left" class="mb-0" type="dashed">
                <b>Detalhamento das informações do Atestado de Saúde Ocupacional - ASO</b>
            </Divider>
            <div class="field col-3 m-0">
                <label :class="{'p-error':v$.form.exMedOcup.aso.dtAso.$invalid && submitted}" for="asoDtAso">
                    Data emissão <span class="p-error">*</span>
                </label>
                <Calendar id="asoDtAso" v-model="dtAso"
                          :class="{'p-invalid':v$.form.exMedOcup.aso.dtAso.$invalid && submitted}"
                          :showButtonBar="true" :showIcon="true" autocomplete="off"/>
                <small
                    v-if="(v$.form.exMedOcup.aso.dtAso.$invalid && submitted) || v$.form.exMedOcup.aso.dtAso.$pending.$response"
                    class="p-error">{{ v$.form.exMedOcup.aso.dtAso.required.$message }}</small>
            </div>
            <div class="field col-3 m-0">
                <label for="asoResAso">Resultado do ASO</label>
                <Dropdown id="asoResAso" v-model="form.exMedOcup.aso.resAso"
                          :options="asoResultados" :showClear="true" optionLabel="name" optionValue="id"/>
            </div>

            <FormExame :exame="form.exMedOcup.aso.exame" :exameCreate="exameCreate" :exameRemove="exameRemove"
                       :exameUpdate="exameUpdate"/>
            <div class="field col-12 m-0 pt-0">
                <small
                    v-if="(v$.form.exMedOcup.aso.exame.$invalid && submitted) || v$.form.exMedOcup.aso.exame.$pending.$response"
                    class="p-error">{{ v$.form.exMedOcup.aso.exame.required.$message }}</small>
            </div>

            <Divider align="left" class="mb-0" type="dashed">
                <b>Informações sobre o médico emitente do ASO</b>
            </Divider>
            <div class="field col-6 m-0">
                <label :class="{'p-error':v$.form.exMedOcup.aso.medico.nmMed.$invalid && submitted}"
                       for="medicoNmMed">
                    Nome <span class="p-error">*</span>
                </label>
                <InputText id="medicoNmMed" v-model="form.exMedOcup.aso.medico.nmMed"
                           :class="{'p-invalid':v$.form.exMedOcup.aso.medico.nmMed.$invalid && submitted}"/>
                <small
                    v-if="(v$.form.exMedOcup.aso.medico.nmMed.$invalid && submitted) || v$.form.exMedOcup.aso.medico.nmMed.$pending.$response"
                    class="p-error">{{ v$.form.exMedOcup.aso.medico.nmMed.required.$message }}</small>
            </div>
            <div class="field col-3 m-0">
                <label :class="{'p-error':v$.form.exMedOcup.aso.medico.nrCRM.$invalid && submitted}" for="medicoNrCRM">
                    Número CRM <span class="p-error">*</span>
                </label>
                <InputText id="medicoNrCRM" v-model="form.exMedOcup.aso.medico.nrCRM"
                           :class="{'p-invalid':v$.form.exMedOcup.aso.medico.nrCRM.$invalid && submitted}"/>
                <small
                    v-if="(v$.form.exMedOcup.aso.medico.nrCRM.$invalid && submitted) || v$.form.exMedOcup.aso.medico.nrCRM.$pending.$response"
                    class="p-error">{{ v$.form.exMedOcup.aso.medico.nrCRM.required.$message }}</small>
            </div>
            <div class="field col-3 m-0">
                <label :class="{'p-error':v$.form.exMedOcup.aso.medico.ufCRM.$invalid && submitted}" for="medicoUfCRM">
                    UF CRM <span class="p-error">*</span>
                </label>
                <DropdownEsocialEstado id="medicoUfCRM" v-model="medicoUfCRM"
                                       :class="{'p-invalid':v$.form.exMedOcup.aso.medico.ufCRM.$invalid && submitted}"
                                       :idValue="form.exMedOcup.aso.medico.ufCRM" optionLabel="nome"/>
                <small
                    v-if="(v$.form.exMedOcup.aso.medico.ufCRM.$invalid && submitted) || v$.form.exMedOcup.aso.medico.ufCRM.$pending.$response"
                    class="p-error">{{ v$.form.exMedOcup.aso.medico.ufCRM.required.$message }}</small>
            </div>

            <Divider align="left" class="mb-0" type="dashed">
                <b>Informações sobre o médico responsável/coordenador do PCMSO</b>
            </Divider>
            <div class="field col-6 m-0">
                <label for="respMonitNmResp">Nome</label>
                <InputText id="respMonitNmResp" v-model="form.exMedOcup.respMonit.nmResp"/>
            </div>
            <div class="field col-3 m-0">
                <label for="respMonitCpfResp">CPF</label>
                <InputMask id="respMonitCpfResp" v-model="form.exMedOcup.respMonit.cpfResp" :unmask="true"
                           mask="999.999.999-99"/>
            </div>
            <div class="field col-3 m-0">
                <label for="respMonitNrCRM">Número CRM</label>
                <InputText id="respMonitNrCRM" v-model="form.exMedOcup.respMonit.nrCRM"/>
            </div>
            <div class="field col-3 m-0">
                <label for="respMonitUfCRM">UF CRM</label>
                <DropdownEsocialEstado id="respMonitUfCRM" v-model="respMonitUfCRM"
                                       :idValue="form.exMedOcup.respMonit.ufCRM" optionLabel="nome"/>
            </div>

            <Toolbar class="w-full">
                <template #start>
                    <Button :loading="isLoading" class="p-button w-8rem p-button-outlined" icon="pi pi-times"
                            label="Cancelar" @click="$router.go(-1)"></Button>
                </template>

                <template #end>
                    <Button :loading="isLoading" class="p-button w-8rem" icon="pi pi-check" label="Salvar"
                            @click="save"/>
                </template>
            </Toolbar>
        </div>
    </form>
</template>

<script>
import FormExame from './FormExame';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import BaseService from '@/services/BaseService';
import { lightFormat } from 'date-fns';
import { showError, showSuccess } from '@/utils/Toast';
import DropdownEsocialEstado from '../_shared/DropdownEsocialEstado.vue';

const tabela01Categorias = require('../../_tabelas-esocial/tabela-01.json');

export default {
    setup: () => ({ v$: useVuelidate() }),
    components: { FormExame, DropdownEsocialEstado },
    props: [
        'customerId',
        'employeeId',
        'recordId',
        'recordJson',
        'removeEmptyValues'
    ],
    data() {
        return {
            submitted: false,
            isLoading: false,
            dtAso: undefined,
            form: {
                exMedOcup: {
                    tpExameOcup: undefined,
                    aso: {
                        dtAso: undefined,
                        resAso: undefined,
                        exame: [],
                        medico: {
                            nmMed: undefined,
                            nrCRM: undefined,
                            ufCRM: undefined
                        }
                    },
                    respMonit: {
                        cpfResp: undefined,
                        nmResp: undefined,
                        nrCRM: undefined,
                        ufCRM: undefined
                    }
                }
            },
            medicoUfCRM: undefined,
            respMonitUfCRM: undefined,
            trabalhadorCategorias: tabela01Categorias,
            examesTipos: [
                { id: '0', name: 'Exame médico admissional' },
                {
                    id: '1',
                    name: 'Exame médico periódico, conforme Norma Regulamentadora 07 - NR-07 e/ou planejamento do Programa de Controle Médico de Saúde Ocupacional - PCMSO'
                },
                { id: '2', name: 'Exame médico de retorno ao trabalho' },
                { id: '3', name: 'Exame médico de mudança de função ou de mudança de risco ocupacional' },
                { id: '4', name: 'Exame médico de monitoração pontual, não enquadrado nos demais casos' },
                { id: '9', name: 'Exame médico demissional' },
            ],
            asoResultados: [
                { id: 1, name: 'Apto' },
                { id: 2, name: 'Inapto' }
            ]
        };
    },
    validations() {
        return {
            form: {
                exMedOcup: {
                    tpExameOcup: {
                        required: helpers.withMessage('Tipo do exame médico ocupacional deve ser informado', required)
                    },
                    aso: {
                        dtAso: {
                            required: helpers.withMessage('Data de emissão do ASO deve ser informado', required)
                        },
                        exame: {
                            required: helpers.withMessage('Deve ser adicionado no minímo um exame', required)
                        },
                        medico: {
                            nmMed: {
                                required: helpers.withMessage('Nome do médico deve ser informado', required)
                            },
                            nrCRM: {
                                required: helpers.withMessage('Número de inscrição deve ser informado', required)
                            },
                            ufCRM: {
                                required: helpers.withMessage('Estado deve ser informado', required)
                            }
                        }
                    }
                }
            }
        };
    },
    mounted() {
        this.$service = new BaseService('esocial/clientes/:customerId/funcionarios/:employeeId/eventos/S2220');

        if (this.recordId) {
            this.form = this.recordJson;
            this.form.id = this.recordId;

            if (!this.form.exMedOcup.respMonit) {
                this.form.exMedOcup.respMonit = {};
            }

            if (this.form.exMedOcup.aso.dtAso) {
                this.dtAso = new Date(`${this.form.exMedOcup.aso.dtAso}T00:00`);
            }
        }
    },
    watch: {
        dtAso: function (newValue) {
            this.form.exMedOcup.aso.dtAso = this.formatDate(newValue);
            this.dtAso = newValue;
        },
        medicoUfCRM: function (newValue) {
            this.form.exMedOcup.aso.medico.ufCRM = newValue ? newValue.sigla : null;
        },
        respMonitUfCRM: function (newValue) {
            this.form.exMedOcup.respMonit.ufCRM = newValue ? newValue.sigla : null;
        }
    },
    methods: {
        formatDate(dateString) {
            const formatDates = lightFormat(new Date(dateString), 'yyyy-MM-dd');
            return `${formatDates}`;
        },
        exameCreate(exame) {
            this.form.exMedOcup.aso.exame.push(exame);
        },
        exameUpdate(index, exame) {
            this.form.exMedOcup.aso.exame.splice(index, 1, exame);
        },
        exameRemove(index) {
            this.form.exMedOcup.aso.exame.splice(index, 1);
        },
        async save() {
            this.submitted = true;

            const result = await this.v$.form.$validate();
            if (!result) {
                return;
            }

            this.$service.path = this.$service.path.replace(':customerId', this.customerId);
            this.$service.path = this.$service.path.replace(':employeeId', this.employeeId);

            const form = this.removeEmptyValues(JSON.parse(JSON.stringify(this.form)));

            this.isLoading = true;
            try {
                await this.$service.save(form);
                showSuccess(this.$toast, (!this.form.id ? 'Registro criado' : 'Registro atualizado'));
                await this.$router.push('/esocial/list');
            } catch (error) {
                showError(this.$toast, error);
            } finally {
                this.submitted = false;
                this.isLoading = false;
            }
        }
    }
};
</script>
