<template>
    <form id="form" ref="form">
        <div class="p-fluid grid">
            <Divider align="left" class="mb-0" type="dashed">
                <b>Informações sobre o ambiente de trabalho</b>
            </Divider>
            <div class="field col-3 m-0">
                <label :class="{'p-error':v$.form.exposicao.inicio.$invalid && submitted}"
                       for="exposicaoInicio">
                    Data de início <span class="p-error">*</span>
                </label>
                <Calendar id="exposicaoInicio" v-model="exposicaoInicio"
                          :class="{'p-invalid':v$.form.exposicao.inicio.$invalid && submitted}"
                          :showButtonBar="true" :showIcon="true" autocomplete="off"/>
                <small v-if="(v$.form.exposicao.inicio.$invalid && submitted)"
                       class="p-error">{{ v$.form.exposicao.inicio.required.$message }}</small>
            </div>

            <FormAmbiente :ambienteCreate="ambienteCreate" :ambienteRemove="ambienteRemove"
                          :ambienteUpdate="ambienteUpdate" :ambientes="form.exposicao.ambientes"/>
            <div class="field col-12 m-0 pt-0">
                <small v-if="v$.form.exposicao.ambientes.$invalid && submitted"
                       class="p-error">{{ v$.form.exposicao.ambientes.required.$message }}</small>
            </div>

            <Divider align="left" class="mb-0" type="dashed">
                <b>Descrição das atividades desempenhadas <span class="p-error">*</span></b>
            </Divider>
            <div class="field col-12 m-0">
                <Textarea id="exposicaoDescricao" v-model="form.exposicao.atividade.descricao"
                          :class="{'p-invalid':v$.form.exposicao.atividade.descricao.$invalid && submitted}"/>
                <small v-if="(v$.form.exposicao.atividade.descricao.$invalid && submitted)"
                       class="p-error">{{ v$.form.exposicao.atividade.descricao.required.$message }}</small>
            </div>

            <FormAgente :agenteCreate="agenteCreate" :agenteRemove="agenteRemove" :agenteUpdate="agenteUpdate"
                        :agentes="form.exposicao.agentes" :removeEmptyValues="removeEmptyValues"/>
            <div class="field col-12 m-0 pt-0">
                <small v-if="v$.form.exposicao.agentes.$invalid && submitted"
                       class="p-error">{{ v$.form.exposicao.agentes.required.$message }}</small>
            </div>

            <FormResponsavel :responsaveis="form.exposicao.responsaveis" :responsavelCreate="responsavelCreate"
                             :responsavelRemove="responsavelRemove" :responsavelUpdate="responsavelUpdate"/>
            <div class="field col-12 m-0 pt-0">
                <small v-if="v$.form.exposicao.responsaveis.$invalid && submitted"
                       class="p-error">{{ v$.form.exposicao.responsaveis.required.$message }}</small>
            </div>

            <Divider align="left" class="mb-0" type="dashed">
                <b>Observações relativas a registros ambientais</b>
            </Divider>
            <div class="field col-12 m-0">
                <Textarea id="exposicaoObservacao" v-model="form.exposicao.observacao.descricao"/>
            </div>

            <Toolbar class="w-full">
                <template #start>
                    <Button :loading="isLoading" class="p-button w-8rem p-button-outlined" icon="pi pi-times"
                            label="Cancelar" @click="$router.go(-1)"></Button>
                </template>

                <template #end>
                    <Button :loading="isLoading" class="p-button w-8rem" icon="pi pi-check" label="Salvar"
                            @click="save"/>
                </template>
            </Toolbar>
        </div>
    </form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import BaseService from '@/services/BaseService';
import { lightFormat } from 'date-fns';
import FormAmbiente from './FormAmbiente';
import FormAgente from './FormAgente';
import FormResponsavel from './FormResponsavel';
import { showError, showSuccess } from '../../../../../utils/Toast';

export default {
    components: { FormResponsavel, FormAgente, FormAmbiente },
    setup: () => ({ v$: useVuelidate() }),
    props: [
        'customerId',
        'employeeId',
        'recordId',
        'recordJson',
        'removeEmptyValues'
    ],
    data() {
        return {
            submitted: false,
            isLoading: false,
            form: {
                exposicao: {
                    ambientes: [],
                    agentes: [],
                    responsaveis: [],
                    atividade: {},
                    observacao: {}
                }
            },
            exposicaoInicio: undefined,
            selectSimNao: [
                { id: 'S', name: 'Sim' },
                { id: 'N', name: 'Não' }
            ]
        };
    },
    validations() {
        return {
            form: {
                exposicao: {
                    inicio: { required: helpers.withMessage('Data de início deve ser informada', required) },
                    ambientes: { required: helpers.withMessage('Deve ser adicionado no minímo um ambiente', required) },
                    agentes: { required: helpers.withMessage('Deve ser adicionado no minímo um agente', required) },
                    responsaveis: { required: helpers.withMessage('Deve ser adicionado no minímo um responsável', required) },
                    atividade: {
                        descricao: { required: helpers.withMessage('Descrição deve ser informada', required) }
                    }
                }
            }
        };
    },
    mounted() {
        this.$service = new BaseService('esocial/clientes/:customerId/funcionarios/:employeeId/eventos/S2240');

        if (this.recordId) {
            this.form = this.recordJson;
            this.form.id = this.recordId;

            if (!this.form.exposicao.observacao) {
                this.form.exposicao.observacao = {};
            }

            this.exposicaoInicio = new Date(`${this.form.exposicao.inicio}T00:00`);
        }
    },
    watch: {
        exposicaoInicio: function (newValue) {
            this.form.exposicao.inicio = this.formatDate(newValue);
            this.exposicaoInicio = newValue;
        }
    },
    methods: {
        formatDate(dateString) {
            const formatDates = lightFormat(new Date(dateString), 'yyyy-MM-dd');
            return `${formatDates}`;
        },
        ambienteCreate(ambiente) {
            this.form.exposicao.ambientes.push(ambiente);
        },
        ambienteUpdate(index, ambiente) {
            this.form.exposicao.ambientes.splice(index, 1, ambiente);
        },
        ambienteRemove(index) {
            this.form.exposicao.ambientes.splice(index, 1);
        },
        agenteCreate(agente) {
            this.form.exposicao.agentes.push(agente);
        },
        agenteUpdate(index, agente) {
            this.form.exposicao.agentes.splice(index, 1, agente);
        },
        agenteRemove(index) {
            this.form.exposicao.agentes.splice(index, 1);
        },
        responsavelCreate(responsavel) {
            this.form.exposicao.responsaveis.push(responsavel);
        },
        responsavelUpdate(index, responsavel) {
            this.form.exposicao.responsaveis.splice(index, 1, responsavel);
        },
        responsavelRemove(index) {
            this.form.exposicao.responsaveis.splice(index, 1);
        },
        async save() {
            this.submitted = true;

            const result = await this.v$.form.$validate();
            if (!result) {
                return;
            }

            this.$service.path = this.$service.path.replace(':customerId', this.customerId);
            this.$service.path = this.$service.path.replace(':employeeId', this.employeeId);

            const form = this.removeEmptyValues(JSON.parse(JSON.stringify(this.form)));

            this.isLoading = true;
            try {
                await this.$service.save(form);
                showSuccess(this.$toast, (!this.form.id ? 'Registro criado' : 'Registro atualizado'));
                await this.$router.push('/esocial/list');
            } catch (error) {
                showError(this.$toast, error);
            } finally {
                this.submitted = false;
                this.isLoading = false;
            }
        }
    }
};
</script>
