<template>
    <form id="form" ref="form">
        <div class="p-fluid grid">
            <Divider align="left" class="mb-0" type="dashed">
                <b>Comunicação de Acidente de Trabalho - CAT</b>
            </Divider>
            <div class="field col-2 m-0">
                <label :class="{'p-error':v$.form.acidente.data.$invalid && submitted}" for="acidenteData">
                    Data do acidente <span class="p-error">*</span>
                </label>
                <Calendar id="acidenteData" v-model="acidenteData"
                          :class="{'p-invalid':v$.form.acidente.data.$invalid && submitted}"
                          :showButtonBar="true" :showIcon="true" autocomplete="off"/>
                <small v-if="(v$.form.acidente.data.$invalid && submitted)"
                       class="p-error">{{ v$.form.acidente.data.required.$message }}</small>
            </div>
            <div class="field col-2 m-0">
                <label :class="{'p-error':v$.form.acidente.tipo.$invalid && submitted}" for="acidenteTipo">
                    Tipo de acidente <span class="p-error">*</span>
                </label>
                <Dropdown id="acidenteTipo" v-model="form.acidente.tipo"
                          :class="{'p-invalid':v$.form.acidente.tipo.$invalid && submitted}"
                          :options="acidenteTipos" :showClear="true" optionLabel="name" optionValue="id"/>
                <small v-if="(v$.form.acidente.tipo.$invalid && submitted)"
                       class="p-error">{{ v$.form.acidente.tipo.required.$message }}</small>
            </div>
            <div class="field col-2 m-0">
                <label for="acidenteHora">Hora do acidente</label>
                <InputMask id="acidenteHora" v-model="form.acidente.hora" :unmask="true" mask="99:99" slotChar="HH:MM"/>
            </div>
            <div class="field col-2 m-0">
                <label for="acidenteHorasTrabalhadas">Horas trabalhadas antes</label>
                <InputMask id="acidenteHorasTrabalhadas" v-model="form.acidente.horasTrabalhadas" :unmask="true"
                           mask="99:99" slotChar="HH:MM"/>
            </div>
            <div class="field col-2 m-0">
                <label :class="{'p-error':v$.form.acidente.tipoCat.$invalid && submitted}" for="acidenteTipoCat">
                    Tipo de CAT <span class="p-error">*</span>
                </label>
                <Dropdown id="acidenteTipoCat" v-model="form.acidente.tipoCat"
                          :class="{'p-invalid':v$.form.acidente.tipoCat.$invalid && submitted}"
                          :options="acidenteTiposCat" :showClear="true" optionLabel="name" optionValue="id"/>
                <small v-if="(v$.form.acidente.tipoCat.$invalid && submitted)"
                       class="p-error">{{ v$.form.acidente.tipoCat.required.$message }}</small>
            </div>
            <div class="field col-2 m-0">
                <label :class="{'p-error':v$.form.acidente.origem.recibo.$invalid && submitted}"
                       for="acidenteUltimoRecibo">
                    Recibo
                    <span v-if="isAcidenteTipoCatReabertura" class="p-error">*</span>
                    <i class="pi pi-info-circle ml-1"
                       title="Informar o número do recibo da última CAT referente ao mesmo acidente/doença relacionada ao trabalho."></i>
                </label>
                <InputText id="acidenteUltimoRecibo" v-model="form.acidente.origem.recibo"
                           :class="{'p-invalid':v$.form.acidente.origem.recibo.$invalid && submitted}"
                           :disabled="!isAcidenteTipoCatReabertura && !isAcidenteTipoCatComunicacao"/>
                <small v-if="(v$.form.acidente.origem.recibo.$invalid && submitted)"
                       class="p-error">{{ v$.form.acidente.origem.recibo.required.$message }}</small>
            </div>
            <div class="field col-2 m-0">
                <label :class="{'p-error':v$.form.acidente.obito.$invalid && submitted}" for="acidenteObito">
                    Houve óbito? <span class="p-error">*</span>
                </label>
                <Dropdown id="acidenteObito" v-model="form.acidente.obito"
                          :class="{'p-invalid':v$.form.acidente.obito.$invalid && submitted}"
                          :options="selectSimNao" :showClear="true" optionLabel="name" optionValue="id"/>
                <small v-if="(v$.form.acidente.obito.$invalid && submitted)"
                       class="p-error">{{ v$.form.acidente.obito.required.$message }}</small>
            </div>
            <div class="field col-2 m-0">
                <label for="acidenteDataObito">Data do óbito</label>
                <Calendar id="acidenteDataObito" v-model="acidenteDataObito" :showButtonBar="true" :showIcon="true"
                          autocomplete="off"/>
            </div>
            <div class="field col-2 m-0">
                <label :class="{'p-error':v$.form.acidente.comunicadoPolicia.$invalid && submitted}"
                       for="acidenteComunicadoPolicia">
                    Comunicado policia? <span class="p-error">*</span>
                </label>
                <Dropdown id="acidenteComunicadoPolicia" v-model="form.acidente.comunicadoPolicia"
                          :class="{'p-invalid':v$.form.acidente.comunicadoPolicia.$invalid && submitted}"
                          :options="selectSimNao" :showClear="true" optionLabel="name" optionValue="id"/>
                <small v-if="(v$.form.acidente.comunicadoPolicia.$invalid && submitted)"
                       class="p-error">{{ v$.form.acidente.comunicadoPolicia.required.$message }}</small>
            </div>
            <div class="field col-4 m-0">
                <label :class="{'p-error':v$.form.acidente.codigoSituacao.$invalid && submitted}"
                       for="acidenteCodigoSituacao">
                    Código da situação geradora <span class="p-error">*</span>
                </label>
                <Dropdown id="acidenteCodigoSituacao" v-model="form.acidente.codigoSituacao"
                          :class="{'p-invalid':v$.form.acidente.codigoSituacao.$invalid && submitted}"
                          :options="acidenteSituacoes" :showClear="true" optionLabel="name" optionValue="id"/>
                <small v-if="(v$.form.acidente.codigoSituacao.$invalid && submitted)"
                       class="p-error">{{ v$.form.acidente.codigoSituacao.required.$message }}</small>
            </div>
            <div class="field col-2 m-0">
                <label :class="{'p-error':v$.form.acidente.iniciativa.$invalid && submitted}" for="acidenteIniciativa">
                    Iniciativa da CAT <span class="p-error">*</span>
                </label>
                <Dropdown id="acidenteIniciativa" v-model="form.acidente.iniciativa"
                          :class="{'p-invalid':v$.form.acidente.iniciativa.$invalid && submitted}"
                          :options="acidenteIniciativas" :showClear="true" optionLabel="name" optionValue="id"/>
                <small v-if="(v$.form.acidente.iniciativa.$invalid && submitted)"
                       class="p-error">{{ v$.form.acidente.iniciativa.required.$message }}</small>
            </div>
            <div class="field col-2 m-0">
                <label for="acidenteUltimoDia">Último dia trabalhado</label>
                <Calendar id="acidenteUltimoDia" v-model="acidenteUltimoDia" :showButtonBar="true" :showIcon="true"
                          autocomplete="off"/>
            </div>
            <div class="field col-2 m-0">
                <label for="acidenteAfastamento">Houve afastamento?</label>
                <Dropdown id="acidenteAfastamento" v-model="form.acidente.afastamento"
                          :options="selectSimNao" :showClear="true" optionLabel="name" optionValue="id"/>
            </div>
            <div class="field col-12 m-0">
                <label for="acidenteObservacao">Observação</label>
                <Textarea id="acidenteObservacao" v-model="form.acidente.observacao"/>
            </div>

            <Divider align="left" class="mb-0" type="dashed">
                <b>Local do acidente</b>
            </Divider>
            <div class="col-6 p-0">
                <div class="field col-12 m-0">
                    <label :class="{'p-error':v$.form.acidente.local.tipo.$invalid && submitted}"
                           for="acidenteLocalTipo">
                        Tipo do local <span class="p-error">*</span>
                    </label>
                    <Dropdown id="acidenteLocalTipo" v-model="form.acidente.local.tipo"
                              :class="{'p-invalid':v$.form.acidente.local.tipo.$invalid && submitted}"
                              :options="acidenteLocalTipos" :showClear="true" optionLabel="name" optionValue="id"/>
                    <small v-if="(v$.form.acidente.local.tipo.$invalid && submitted)"
                           class="p-error">{{ v$.form.acidente.local.tipo.required.$message }}</small>
                </div>
                <div class="field col-12 m-0">
                    <label for="acidenteLocalDescricao">Descrição do local do acidente</label>
                    <Textarea id="acidenteLocalDescricao" v-model="form.acidente.local.descricao" rows="3"/>
                </div>
            </div>
            <div class="col-6 p-0">
                <div class="field col-12 m-0">
                    <label for="acidenteLocalLogradouroTipo">Tipo de logradouro</label>
                    <Dropdown id="acidenteLocalLogradouroTipo" v-model="form.acidente.local.logradouroTipo"
                              :filter="true" :options="acidenteLocalLogradouroTipos" :showClear="true"
                              optionLabel="name" optionValue="id"/>
                </div>
                <div class="field col-12 m-0">
                    <label :class="{'p-error':v$.form.acidente.local.logradouroDescricao.$invalid && submitted}"
                           for="acidenteLocalLogradouroDescricao">
                        Descrição do logradouro <span class="p-error">*</span>
                    </label>
                    <Textarea id="acidenteLocalLogradouroDescricao" v-model="form.acidente.local.logradouroDescricao"
                              :class="{'p-invalid':v$.form.acidente.local.logradouroDescricao.$invalid && submitted}"
                              rows="3"/>
                    <small v-if="(v$.form.acidente.local.logradouroDescricao.$invalid && submitted)"
                           class="p-error">{{ v$.form.acidente.local.logradouroDescricao.required.$message }}</small>
                </div>
            </div>
            <div class="field col-2 m-0">
                <label for="acidenteLocalPais">País</label>
                <DropdownEsocialPais id="acidenteLocalPais" v-model="acidenteLocalPais"
                                     :idValue="form.acidente.local.pais" optionLabel="nome"/>
            </div>
            <div class="field col-2 m-0">
                <label for="acidenteLocalEstado">Estado</label>
                <DropdownEsocialEstado id="acidenteLocalEstado" v-model="acidenteLocalEstado"
                                       :idValue="form.acidente.local.estado" optionLabel="nome"/>
            </div>
            <div class="field col-2 m-0">
                <label for="acidenteLocalMunicipio">Cidade</label>
                <DropdownEsocialCidade id="acidenteLocalMunicipio" v-model="acidenteLocalMunicipio"
                                       :idValue="form.acidente.local.municipio" optionLabel="cidade"/>
            </div>
            <div class="field col-3 m-0">
                <label for="acidenteLocalBairro">Bairro</label>
                <InputText id="acidenteLocalBairro" v-model="form.acidente.local.bairro"/>
            </div>
            <div class="field col-3 m-0">
                <label for="acidenteLocalComplemento">Complemento</label>
                <InputText id="acidenteLocalComplemento" v-model="form.acidente.local.complemento"/>
            </div>
            <div class="field col-2 m-0">
                <label :class="{'p-error':v$.form.acidente.local.logradouroNumero.$invalid && submitted}"
                       for="acidenteLocalLogradouroNumero">
                    Número do logradouro <span class="p-error">*</span>
                </label>
                <InputText id="acidenteLocalLogradouroNumero" v-model="form.acidente.local.logradouroNumero"
                           :class="{'p-invalid':v$.form.acidente.local.logradouroNumero.$invalid && submitted}"/>
                <small v-if="(v$.form.acidente.local.logradouroNumero.$invalid && submitted)"
                       class="p-error">{{ v$.form.acidente.local.logradouroNumero.required.$message }}</small>
            </div>
            <div class="field col-2 m-0">
                <label for="acidenteLocalCep">CEP</label>
                <InputMask id="acidenteLocalCep" v-model="form.acidente.local.cep" :unmask="true" mask="99999-999"/>
            </div>
            <div class="field col-2 m-0">
                <label for="acidenteLocalCodigoPostal">Código postal</label>
                <InputText id="acidenteLocalCodigoPostal" v-model="form.acidente.local.codigoPostal"/>
            </div>

            <Divider align="left" class="mb-0" type="dashed">
                <b>Identificação do local onde ocorreu o acidente</b>
            </Divider>
            <div class="field col-2 m-0">
                <label for="acidenteLocalIdentificacaoTipo">Tipo inscrição</label>
                <Dropdown id="acidenteLocalIdentificacaoTipo" v-model="form.acidente.local.identificacao.tipo"
                          :options="acidenteLocalIdentificacaoTipos" :showClear="true" optionLabel="name"
                          optionValue="id"/>
            </div>
            <div class="field col-2 m-0">
                <label for="acidenteLocalIdentificacaoInscricao">Número inscrição</label>
                <InputText id="acidenteLocalIdentificacaoInscricao"
                           v-model="form.acidente.local.identificacao.inscricao"/>
            </div>

            <Divider align="left" class="mb-0" type="dashed">
                <b>Detalhamento da parte atingida</b>
            </Divider>
            <div class="field col-4 m-0">
                <label :class="{'p-error':v$.form.acidente.parteAtingida.codigo.$invalid && submitted}"
                       for="acidenteParteAtingidaCodigo">
                    Parte atingida <span class="p-error">*</span>
                </label>
                <Dropdown id="acidenteParteAtingidaCodigo" v-model="form.acidente.parteAtingida.codigo"
                          :class="{'p-invalid':v$.form.acidente.parteAtingida.codigo.$invalid && submitted}"
                          :options="acidenteParteAtingidaCodigos" :showClear="true" optionLabel="name"
                          optionValue="id"/>
                <small v-if="(v$.form.acidente.parteAtingida.codigo.$invalid && submitted)"
                       class="p-error">{{ v$.form.acidente.parteAtingida.codigo.required.$message }}</small>
            </div>
            <div class="field col-2 m-0">
                <label :class="{'p-error':v$.form.acidente.parteAtingida.lateralidade.$invalid && submitted}"
                       for="acidenteParteAtingidaLateralidade">
                    Lateralidade <span class="p-error">*</span>
                </label>
                <Dropdown id="acidenteParteAtingidaLateralidade" v-model="form.acidente.parteAtingida.lateralidade"
                          :class="{'p-invalid':v$.form.acidente.parteAtingida.lateralidade.$invalid && submitted}"
                          :options="parteAtingidaLateralidades" :showClear="true" optionLabel="name" optionValue="id"/>
                <small v-if="(v$.form.acidente.parteAtingida.lateralidade.$invalid && submitted)"
                       class="p-error">{{ v$.form.acidente.parteAtingida.lateralidade.required.$message }}</small>
            </div>

            <Divider align="left" class="mb-0" type="dashed">
                <b>Detalhamento do agente causador</b>
            </Divider>
            <div class="field col-12 m-0">
                <label :class="{'p-error':v$.form.acidente.agenteCausador.codigo.$invalid && submitted}"
                       for="acidenteAgenteCausador">
                    Agente Causador <span class="p-error">*</span>
                </label>
                <Dropdown id="acidenteAgenteCausador" v-model="form.acidente.agenteCausador.codigo"
                          :class="{'p-invalid':v$.form.acidente.agenteCausador.codigo.$invalid && submitted}"
                          :options="acidenteAgentesCausadores" :showClear="true" optionLabel="name" optionValue="id"/>
                <small v-if="(v$.form.acidente.agenteCausador.codigo.$invalid && submitted)"
                       class="p-error">{{ v$.form.acidente.agenteCausador.codigo.required.$message }}</small>
            </div>

            <Divider align="left" class="mb-0" type="dashed">
                <b>Atestado médico</b>
            </Divider>
            <div class="field col-2 m-0">
                <label :class="{'p-error':v$.form.acidente.atestado.data.$invalid && submitted}"
                       for="acidenteAtestadoData">
                    Data do atendimento <span class="p-error">*</span>
                </label>
                <Calendar id="acidenteAtestadoData" v-model="atestadoData"
                          :class="{'p-invalid':v$.form.acidente.atestado.data.$invalid && submitted}"
                          :showButtonBar="true" :showIcon="true" autocomplete="off"/>
                <small v-if="(v$.form.acidente.atestado.data.$invalid && submitted)"
                       class="p-error">{{ v$.form.acidente.atestado.data.required.$message }}</small>
            </div>
            <div class="field col-2 m-0">
                <label :class="{'p-error':v$.form.acidente.atestado.hora.$invalid && submitted}"
                       for="acidenteAtestadoHora">
                    Hora do atendimento <span class="p-error">*</span>
                </label>
                <InputMask id="acidenteAtestadoHora" v-model="form.acidente.atestado.hora"
                           :class="{'p-invalid':v$.form.acidente.atestado.hora.$invalid && submitted}" :unmask="true"
                           mask="99:99" slotChar="HH:MM"/>
                <small v-if="(v$.form.acidente.atestado.hora.$invalid && submitted)"
                       class="p-error">{{ v$.form.acidente.atestado.hora.required.$message }}</small>
            </div>
            <div class="field col-2 m-0">
                <label :class="{'p-error':v$.form.acidente.atestado.internacao.$invalid && submitted}"
                       for="acidenteAtestadoInternacao">
                    Indicativo de internação <span class="p-error">*</span>
                </label>
                <Dropdown id="acidenteAtestadoInternacao" v-model="form.acidente.atestado.internacao"
                          :class="{'p-invalid':v$.form.acidente.atestado.internacao.$invalid && submitted}"
                          :options="selectSimNao" :showClear="true" optionLabel="name" optionValue="id"/>
                <small v-if="(v$.form.acidente.atestado.internacao.$invalid && submitted)"
                       class="p-error">{{ v$.form.acidente.atestado.internacao.required.$message }}</small>
            </div>
            <div class="field col-2 m-0">
                <label :class="{'p-error':v$.form.acidente.atestado.duracao.$invalid && submitted}"
                       for="acidenteAtestadoDuracao">
                    Duração tratamento <span class="p-error">*</span>
                </label>
                <InputText id="acidenteAtestadoDuracao" v-model="form.acidente.atestado.duracao"
                           :class="{'p-invalid':v$.form.acidente.atestado.duracao.$invalid && submitted}"/>
                <small v-if="(v$.form.acidente.atestado.duracao.$invalid && submitted)"
                       class="p-error">{{ v$.form.acidente.atestado.duracao.required.$message }}</small>
            </div>
            <div class="field col-2 m-0">
                <label :class="{'p-error':v$.form.acidente.atestado.afastamento.$invalid && submitted}"
                       for="acidenteAtestadoAfastamento">
                    Indicativo de afastamento <span class="p-error">*</span>
                </label>
                <Dropdown id="acidenteAtestadoAfastamento" v-model="form.acidente.atestado.afastamento"
                          :class="{'p-invalid':v$.form.acidente.atestado.afastamento.$invalid && submitted}"
                          :options="selectSimNao" :showClear="true" optionLabel="name" optionValue="id"/>
                <small v-if="(v$.form.acidente.atestado.afastamento.$invalid && submitted)"
                       class="p-error">{{ v$.form.acidente.atestado.afastamento.required.$message }}</small>
            </div>
            <div class="field col-2 m-0">
                <label :class="{'p-error':v$.form.acidente.atestado.cid.$invalid && submitted}"
                       for="acidenteAtestadoCid">
                    CID <span class="p-error">*</span>
                </label>
                <InputMask id="acidenteAtestadoCid" v-model="form.acidente.atestado.cid"
                           :class="{'p-invalid':v$.form.acidente.atestado.cid.$invalid && submitted}"
                           :unmask="true" mask="a99?.9"/>
                <small v-if="(v$.form.acidente.atestado.cid.$invalid && submitted)"
                       class="p-error">{{ v$.form.acidente.atestado.cid.required.$message }}</small>
            </div>
            <div class="field col-12 m-0">
                <label :class="{'p-error':v$.form.acidente.atestado.naturezaLesao.$invalid && submitted}"
                       for="acidenteAtestadoLesao">
                    Natureza da lesão <span class="p-error">*</span>
                </label>
                <Dropdown id="acidenteAtestadoLesao" v-model="form.acidente.atestado.naturezaLesao"
                          :class="{'p-invalid':v$.form.acidente.atestado.naturezaLesao.$invalid && submitted}"
                          :options="acidenteAtestadoLesaoNaturezas" :showClear="true" optionLabel="name"
                          optionValue="id"/>
                <small v-if="(v$.form.acidente.atestado.naturezaLesao.$invalid && submitted)"
                       class="p-error">{{ v$.form.acidente.atestado.naturezaLesao.required.$message }}</small>
            </div>
            <div class="field col-6 m-0">
                <label for="acidenteAtestadoDescricao">Descrição complementar</label>
                <Textarea id="acidenteAtestadoDescricao" v-model="form.acidente.atestado.descricao"/>
            </div>
            <div class="field col-6 m-0">
                <label for="acidenteAtestadoDiagnostico">Diagnóstico provável</label>
                <Textarea id="acidenteAtestadoDiagnostico" v-model="form.acidente.atestado.diagnostico"/>
            </div>
            <div class="field col-12 m-0">
                <label for="acidenteAtestadoObservacao">Observação</label>
                <Textarea id="acidenteAtestadoObservacao" v-model="form.acidente.atestado.observacao"/>
            </div>

            <Divider align="left" class="mb-0" type="dashed">
                <b>Médico/Dentista que emitiu o atestado</b>
            </Divider>
            <div class="field col-4 m-0">
                <label :class="{'p-error':v$.form.acidente.atestado.emitente.nome.$invalid && submitted}"
                       for="acidenteAtestadoEmitenteNome">
                    Nome <span class="p-error">*</span>
                </label>
                <InputText id="acidenteAtestadoEmitenteNome" v-model="form.acidente.atestado.emitente.nome"
                           :class="{'p-invalid':v$.form.acidente.atestado.emitente.nome.$invalid && submitted}"/>
                <small v-if="(v$.form.acidente.atestado.emitente.nome.$invalid && submitted)"
                       class="p-error">{{ v$.form.acidente.atestado.emitente.nome.required.$message }}</small>
            </div>
            <div class="field col-4 m-0">
                <label :class="{'p-error':v$.form.acidente.atestado.emitente.orgao.$invalid && submitted}"
                       for="acidenteAtestadoEmitenteOrgao">
                    Órgão de classe <span class="p-error">*</span>
                </label>
                <Dropdown id="acidenteAtestadoEmitenteOrgao" v-model="form.acidente.atestado.emitente.orgao"
                          :class="{'p-invalid':v$.form.acidente.atestado.emitente.orgao.$invalid && submitted}"
                          :options="acidenteAtestadoEmitenteOrgaos" :showClear="true" optionLabel="name"
                          optionValue="id"/>
                <small v-if="(v$.form.acidente.atestado.emitente.orgao.$invalid && submitted)"
                       class="p-error">{{ v$.form.acidente.atestado.emitente.orgao.required.$message }}</small>
            </div>
            <div class="field col-2 m-0">
                <label :class="{'p-error':v$.form.acidente.atestado.emitente.inscricao.$invalid && submitted}"
                       for="acidenteAtestadoEmitenteInscricao">
                    Número de inscrição <span class="p-error">*</span>
                </label>
                <InputText id="acidenteAtestadoEmitenteInscricao" v-model="form.acidente.atestado.emitente.inscricao"
                           :class="{'p-invalid':v$.form.acidente.atestado.emitente.inscricao.$invalid && submitted}"/>
                <small v-if="(v$.form.acidente.atestado.emitente.inscricao.$invalid && submitted)"
                       class="p-error">{{ v$.form.acidente.atestado.emitente.inscricao.required.$message }}</small>
            </div>
            <div class="field col-2 m-0">
                <label for="acidenteAtestadoEmitenteEstado">Estado</label>
                <DropdownEsocialEstado id="acidenteAtestadoEmitenteEstado" v-model="acidenteAtestadoEmitenteEstado"
                                       :idValue="form.acidente.atestado.emitente.estado" optionLabel="nome"/>
            </div>

            <Toolbar class="w-full">
                <template #start>
                    <Button :loading="isLoading" class="p-button w-8rem p-button-outlined" icon="pi pi-times"
                            label="Cancelar" @click="$router.go(-1)"></Button>
                </template>
                <template #end>
                    <Button :loading="isLoading" class="p-button w-8rem" icon="pi pi-check" label="Salvar"
                            @click="save"/>
                </template>
            </Toolbar>
        </div>
    </form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import BaseService from '@/services/BaseService';
import { lightFormat } from 'date-fns';
import { showError, showSuccess } from '@/utils/Toast';
import DropdownEsocialEstado from '../_shared/DropdownEsocialEstado.vue';
import DropdownEsocialPais from '../_shared/DropdownEsocialPais.vue';
import DropdownEsocialCidade from '@/pages/esocial/eventos/components/_shared/DropdownEsocialCidade.vue';

const tabela13 = require('../../_tabelas-esocial/tabela-13.json');
const tabela14 = require('../../_tabelas-esocial/tabela-14.json');
const tabela15 = require('../../_tabelas-esocial/tabela-15.json');
const tabela17 = require('../../_tabelas-esocial/tabela-17.json');
const tabela20 = require('../../_tabelas-esocial/tabela-20.json');

export default {
    components: { DropdownEsocialCidade, DropdownEsocialEstado, DropdownEsocialPais },
    setup: () => ({ v$: useVuelidate() }),
    props: [
        'customerId',
        'employeeId',
        'recordId',
        'recordJson',
        'removeEmptyValues'
    ],
    data() {
        return {
            submitted: false,
            isLoading: false,
            form: {
                acidente: {
                    local: {
                        identificacao: {}
                    },
                    parteAtingida: {},
                    atestado: {
                        emitente: {}
                    },
                    agenteCausador: {},
                    origem: {}
                }
            },
            acidenteLocalMunicipio: undefined,
            acidenteLocalEstado: undefined,
            acidenteLocalPais: undefined,
            acidenteAtestadoEmitenteEstado: undefined,
            acidenteData: undefined,
            acidenteDataObito: undefined,
            acidenteUltimoDia: undefined,
            atestadoData: undefined,
            selectSimNao: [
                { id: 'S', name: 'Sim' },
                { id: 'N', name: 'Não' }
            ],
            acidenteParteAtingidaCodigos: tabela13,
            acidenteSituacoes: tabela15,
            acidenteLocalLogradouroTipos: tabela20,
            acidenteAgentesCausadores: [...tabela14, ...tabela15],
            acidenteAtestadoLesaoNaturezas: tabela17,
            acidenteTipos: [
                { id: '1', name: 'Típico' },
                { id: '2', name: 'Doença' },
                { id: '3', name: 'Trajeto' }
            ],
            isAcidenteTipoCatReabertura: false,
            isAcidenteTipoCatComunicacao: false,
            acidenteTiposCat: [
                { id: '1', name: 'Inicial' },
                { id: '2', name: 'Reabertura' },
                { id: '3', name: 'Comunicação de óbito' }
            ],
            acidenteIniciativas: [
                { id: '1', name: 'Empregador' },
                { id: '2', name: 'Ordem judicial' },
                { id: '3', name: 'Determinação de órgão fiscalizador' }
            ],
            acidenteLocalTipos: [
                { id: '1', name: 'Estabelecimento do empregador no Brasil' },
                { id: '2', name: 'Estabelecimento do empregador no exterior' },
                { id: '3', name: 'Estabelecimento de terceiros onde o empregador presta serviços' },
                { id: '4', name: 'Via pública' },
                { id: '5', name: 'Área rural' },
                { id: '6', name: 'Embarcação' },
                { id: '9', name: 'Outros' }
            ],
            acidenteLocalIdentificacaoTipos: [
                { id: '1', name: 'CNPJ' },
                { id: '3', name: 'CAEPF' },
                { id: '4', name: 'CNO' }
            ],
            parteAtingidaLateralidades: [
                { id: '0', name: 'Não aplicável' },
                { id: '1', name: 'Esquerda' },
                { id: '2', name: 'Direita' },
                { id: '3', name: 'Ambas' }
            ],
            acidenteAtestadoEmitenteOrgaos: [
                { id: '1', name: 'Conselho Regional de Medicina - CRM' },
                { id: '2', name: 'Conselho Regional de Odontologia - CRO' },
                { id: '3', name: 'Registro do Ministério da Saúde - RMS' }
            ]
        };
    },
    validations() {
        return {
            form: {
                acidente: {
                    data: { required: helpers.withMessage('A data do acidente deve ser informada', required) },
                    tipo: { required: helpers.withMessage('O tipo de acidente deve ser informado', required) },
                    tipoCat: { required: helpers.withMessage('O tipo de CAT deve ser informado', required) },
                    obito: { required: helpers.withMessage('Deve ser informado se houve óbito', required) },
                    comunicadoPolicia: { required: helpers.withMessage('Deve ser informado se foi comunicado a policia', required) },
                    codigoSituacao: { required: helpers.withMessage('Código da situação geradora deve ser informado', required) },
                    iniciativa: { required: helpers.withMessage('Iniciativa da CAT deve ser informado', required) },
                    local: {
                        tipo: { required: helpers.withMessage('Tipo do local deve ser informado', required) },
                        logradouroDescricao: { required: helpers.withMessage('Descrição do logradouro deve ser informada', required) },
                        logradouroNumero: { required: helpers.withMessage('Número do logradouro deve ser informado', required) },
                    },
                    parteAtingida: {
                        codigo: { required: helpers.withMessage('Parte atingida deve ser informada', required) },
                        lateralidade: { required: helpers.withMessage('Lateralidade deve ser informada', required) },
                    },
                    agenteCausador: {
                        codigo: { required: helpers.withMessage('Agente causador deve ser informado', required) },
                    },
                    atestado: {
                        data: { required: helpers.withMessage('Data do atendimento deve ser informada', required) },
                        hora: { required: helpers.withMessage('Hora do atestado deve ser informada', required) },
                        internacao: { required: helpers.withMessage('Indicativo de internação deve ser informada', required) },
                        duracao: { required: helpers.withMessage('Duração tratamento deve ser informada', required) },
                        afastamento: { required: helpers.withMessage('Indicativo de afastamento deve ser informado', required) },
                        naturezaLesao: { required: helpers.withMessage('Natureza da lesão deve ser informada', required) },
                        cid: { required: helpers.withMessage('CID deve ser informado', required) },
                        emitente: {
                            nome: { required: helpers.withMessage('Nome deve ser informado', required) },
                            orgao: { required: helpers.withMessage('Órgão de classe deve ser informado', required) },
                            inscricao: { required: helpers.withMessage('Número de inscrição deve ser informado', required) },
                        }
                    },
                    origem: {
                        recibo: {
                            required: helpers.withMessage(
                                'O recibo da última CAT deve ser informado',
                                requiredIf(() => this.isAcidenteTipoCatReabertura)
                            )
                        }
                    }
                },
            }
        };
    },
    mounted() {
        this.$service = new BaseService('esocial/clientes/:customerId/funcionarios/:employeeId/eventos/S2210');

        if (this.recordId) {
            this.form = this.recordJson;
            this.form.id = this.recordId;

            if (!this.form.acidente.local.identificacao) {
                this.form.acidente.local.identificacao = {};
            }
            if (!this.form.acidente.origem) {
                this.form.acidente.origem = {};
            }

            this.acidenteData = this.form.acidente.data ? new Date(`${this.form.acidente.data}T00:00`) : undefined;
            this.acidenteDataObito = this.form.acidente.dataObito ? new Date(`${this.form.acidente.dataObito}T00:00`) : undefined;
            this.acidenteUltimoDia = this.form.acidente.ultimoDia ? new Date(`${this.form.acidente.ultimoDia}T00:00`) : undefined;
            this.atestadoData = this.form.acidente.atestado.data ? new Date(`${this.form.acidente.atestado.data}T00:00`) : undefined;
        }
    },
    watch: {
        acidenteData: function (newValue) {
            this.form.acidente.data = newValue ? this.formatDate(newValue) : null;
            this.acidenteData = newValue;
        },
        acidenteDataObito: function (newValue) {
            this.form.acidente.dataObito = newValue ? this.formatDate(newValue) : null;
            this.acidenteDataObito = newValue;
        },
        acidenteUltimoDia: function (newValue) {
            this.form.acidente.ultimoDia = newValue ? this.formatDate(newValue) : null;
            this.acidenteUltimoDia = newValue;
        },
        atestadoData: function (newValue) {
            this.form.acidente.atestado.data = newValue ? this.formatDate(newValue) : null;
            this.atestadoData = newValue;
        },
        acidenteLocalMunicipio: function (newValue) {
            this.form.acidente.local.municipio = newValue ? newValue.codigoIbge : null;
        },
        acidenteLocalEstado: function (newValue) {
            this.form.acidente.local.estado = newValue ? newValue.sigla : null;
        },
        acidenteLocalPais: function (newValue) {
            this.form.acidente.local.pais = newValue ? newValue.codigoEsocial : null;
        },
        acidenteAtestadoEmitenteEstado: function (newValue) {
            this.form.acidente.atestado.emitente.estado = newValue ? newValue.sigla : null;
        },
        'form.acidente.tipoCat'(newValue) {
            this.isAcidenteTipoCatReabertura = false;
            this.isAcidenteTipoCatComunicacao = false;

            switch (newValue) {
                case this.acidenteTiposCat[0].id:
                    this.form.acidente.origem.recibo = null;
                    break;
                case this.acidenteTiposCat[1].id:
                    this.isAcidenteTipoCatReabertura = true;
                    break;
                case this.acidenteTiposCat[2].id:
                    this.isAcidenteTipoCatComunicacao = true;
            }
        }
    },
    methods: {
        formatDate(dateString) {
            const formatDates = lightFormat(new Date(dateString), 'yyyy-MM-dd');
            return `${formatDates}`;
        },
        async save() {
            this.submitted = true;

            const result = await this.v$.form.$validate();
            if (!result) {
                return;
            }

            this.$service.path = this.$service.path.replace(':customerId', this.customerId);
            this.$service.path = this.$service.path.replace(':employeeId', this.employeeId);

            const form = this.removeEmptyValues(JSON.parse(JSON.stringify(this.form)));

            this.isLoading = true;
            try {
                await this.$service.save(form);
                showSuccess(this.$toast, (!this.form.id ? 'Registro criado' : 'Registro atualizado'));
                await this.$router.push('/esocial/list');
            } catch (error) {
                showError(this.$toast, error);
            } finally {
                this.submitted = false;
                this.isLoading = false;
            }
        }
    }
};
</script>
