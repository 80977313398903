<template>
    <Dropdown
        v-model="value"
        :filter="true"
        :loading="loading"
        :optionLabel="optionLabel"
        :optionValue="null"
        :options="optionItems"
        :showClear="true"
        placeholder="Selecione a cidade..."
        v-bind="$attrs"
        @filter="filtrar"
    >
        <template #value="slotProps">
            <div v-if="slotProps.value && renderValue">
                {{ formatLabel(this.renderValue(slotProps)) }}
            </div>
            <div v-else-if="slotProps.value && optionLabel">
                {{ slotProps.value[this.optionLabel] }}
            </div>
            <span v-else>
                {{ slotProps.placeholder }}
            </span>
        </template>
        <template #option="slotProps">
            <div v-if="renderOption" :style="localStyleOptions(slotProps)">
                {{ this.renderOption(slotProps) }}
            </div>
            <div v-else-if="slotProps.option && optionLabel" :style="localStyleOptions(slotProps)">
                {{ slotProps.option[this.optionLabel] }}
            </div>
            <span v-else :style="localStyleOptions(slotProps)">
                {{ slotProps.option }}
            </span>
        </template>
        <template #empty="">
            <div>
                <span>Nenhum registro encontrado</span>
            </div>
        </template>
    </Dropdown>
</template>
<script>
import BaseService from '@/services/BaseService';
import { getClientBase } from '@/services/http';
import { limitarCaracteres } from '@/services/auth';

export default {
    name: 'DropdownEsocialCidade',
    props: {
        modelValue: {
            type: Object
        },
        optionLabel: {
            type: String,
            required: true
        },
        optionValue: {
            type: String
        },
        itemSize: {
            type: Number,
            default: 30
        },
        queryDelay: {
            type: Number,
            default: 500
        },
        placeholder: {
            type: String
        },
        renderValue: {
            type: Function
        },
        renderOption: {
            type: Function
        },
        idValue: {
            type: String
        },
        autoLoad: {
            type: Boolean,
            default: true
        },
        resultWithItems: {
            type: Boolean,
            default: true
        },
        styleOptions: {
            type: Function
        },
        filtrosExtras: {
            type: Object
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            service: null,
            loading: false,
            filter: null,
            optionItems: [{}],
            selectedRecord: {},
            timeout: null
        };
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    async created() {
        this.service = new BaseService('/cidades');
    },
    async mounted() {
        await this.loadData(this.idValue);
        if (this.autoLoad) {
            await this.load({ limit: this.itemSize, filter: this.filter, filtrosExtras: this.filtrosExtras });
        }
    },
    watch: {
        idValue(newValue) {
            if (!newValue) {
                return;
            }

            this.loadData(newValue);
        }
    },
    methods: {
        async loadData(idValue) {
            if (!this.idValue) return;

            const found = this.optionItems.find((p) => p.codigoIbge === idValue);

            if (found) {
                this.selectedRecord = found;
            } else {
                const { data: record } = await getClientBase().get(`cidades/ibge/${idValue}`);
                this.selectedRecord = record;
            }

            this.value = this.selectedRecord;
        },
        async filtrar(event) {
            this.filter = event.value;

            if (this.timeout) {
                clearTimeout(this.timeout);
            }

            this.timeout = setTimeout(() => {
                this.load({ limit: this.itemSize, filter: this.filter, filtrosExtras: this.filtrosExtras });
            }, this.queryDelay);
        },
        async load({ limit, filter, filtrosExtras }) {
            try {
                this.loading = true;
                const { data } = await this.service.findAll({ limit, filter, filtrosExtras });
                if (this.resultWithItems) {
                    this.optionItems = data.items;
                } else {
                    this.optionItems = data;
                }

                this.loading = false;
            } catch (error) {
                this.optionItems = [];
                this.loading = false;
                this.$toast.add({
                    severity: 'error',
                    summary: error?.response?.data?.message || error?.message
                });
            }
        },
        localStyleOptions(slotProps) {
            if (this.styleOptions) {
                return this.styleOptions(slotProps);
            }

            return null;
        },
        formatLabel(text) {
            return limitarCaracteres(text, 40);
        }
    }
};
</script>
