<template>
    <Dialog :draggable="false" :modal="true" :style="{width: '600px'}"
            :visible="showModal" header="Cadastrando agente" position="top">
        <form id="formAgente" ref="form">
            <div class="p-fluid grid">
                <div class="field col-12 mb-0">
                    <label :class="{'p-error':v$.form.codigo.$invalid && submitted}" for="codigo">
                        Agente <span class="p-error">*</span>
                    </label>
                    <Dropdown id="codigo" v-model="form.codigo"
                              :class="{'p-invalid':v$.form.codigo.$invalid && submitted}"
                              :filter="true" :options="agenteCodigos" :showClear="true" optionLabel="name"
                              optionValue="id"/>
                    <small v-if="v$.form.codigo.$invalid && submitted"
                           class="p-error">{{ v$.form.codigo.required.$message }}</small>
                </div>
                <div class="field col-12 m-0">
                    <label for="descricao">Descrição</label>
                    <InputText id="descricao" v-model="form.descricao"/>
                </div>
                <div class="field col-4 mb-0">
                    <label for="tipo">Tipo de avaliação</label>
                    <Dropdown id="tipo" v-model="form.tipo" :options="agenteTipos"
                              :showClear="true" optionLabel="name" optionValue="id"/>
                </div>
                <div class="field col-4 m-0">
                    <label for="intensidade">Intensidade</label>
                    <InputText id="intensidade" v-model="form.intensidade"/>
                </div>
                <div class="field col-4 m-0">
                    <label for="limite">Limite</label>
                    <InputText id="limite" v-model="form.limite"/>
                </div>
                <div class="field col-8 mb-0">
                    <label for="unidadeMedida">Unidade de medida</label>
                    <DropdownUnidadesMedida
                        id="unidadeMedida"
                        v-model="unidadeMedida"
                        :idValue="form.unidadeMedida"
                        :showClear="true"/>
                </div>
                <div class="field col-4 m-0">
                    <label for="tecnica">Técnica</label>
                    <InputText id="tecnica" v-model="form.tecnica"/>
                </div>

                <Divider align="left" type="dashed">
                    <b>Informações relativas a Equipamentos (EPC, EPI)</b>
                </Divider>
                <div class="field col-3 mb-0">
                    <label :class="{'p-error':v$.form.equipamento.utilizaEpc.$invalid && submitted}"
                           for="equipamentoUtilizaEpc">
                        Utilização de EPC?
                        <span v-if="isRequiredInfoEquipamentos" class="p-error">*</span>
                    </label>
                    <Dropdown id="equipamentoUtilizaEpc" v-model="form.equipamento.utilizaEpc"
                              :class="{'p-invalid':v$.form.equipamento.utilizaEpc.$invalid && submitted}"
                              :options="equipamentoUtilizaEpc" :showClear="true" optionLabel="name"
                              optionValue="id"/>
                    <small v-if="v$.form.equipamento.utilizaEpc.$invalid && submitted"
                           class="p-error">{{ v$.form.equipamento.utilizaEpc.required.$message }}</small>
                </div>
                <div class="field col-3 mb-0">
                    <label :class="{'p-error':v$.form.equipamento.eficazEpc.$invalid && submitted}"
                           for="equipamentoEficazEpc">
                        EPCs são eficazes?
                        <span v-if="isRequiredInfoEquipamentos" class="p-error">*</span>
                    </label>
                    <Dropdown id="equipamentoEficazEpc" v-model="form.equipamento.eficazEpc"
                              :class="{'p-invalid':v$.form.equipamento.eficazEpc.$invalid && submitted}"
                              :options="selectSimNao" :showClear="true" optionLabel="name"
                              optionValue="id"/>
                    <small v-if="v$.form.equipamento.eficazEpc.$invalid && submitted"
                           class="p-error">{{ v$.form.equipamento.eficazEpc.required.$message }}</small>
                </div>
                <div class="field col-3 mb-0">
                    <label :class="{'p-error':v$.form.equipamento.utilizaEpi.$invalid && submitted}"
                           for="equipamentoUtilizaEpi">
                        Utilização de EPI?
                        <span v-if="isRequiredInfoEquipamentos" class="p-error">*</span>
                    </label>
                    <Dropdown id="equipamentoUtilizaEpi" v-model="form.equipamento.utilizaEpi"
                              :class="{'p-invalid':v$.form.equipamento.utilizaEpi.$invalid && submitted}"
                              :options="equipamentoUtilizaEpi" :showClear="true" optionLabel="name"
                              optionValue="id"/>
                    <small v-if="v$.form.equipamento.utilizaEpi.$invalid && submitted"
                           class="p-error">{{ v$.form.equipamento.utilizaEpi.required.$message }}</small>
                </div>
                <div class="field col-3 mb-0">
                    <label :class="{'p-error':v$.form.equipamento.eficazEpi.$invalid && submitted}"
                           for="equipamentoEficazEpi">
                        EPIs são eficazes?
                        <span v-if="isRequiredInfoEquipamentos" class="p-error">*</span>
                    </label>
                    <Dropdown id="equipamentoEficazEpi" v-model="form.equipamento.eficazEpi"
                              :class="{'p-invalid':v$.form.equipamento.eficazEpi.$invalid && submitted}"
                              :options="selectSimNao" :showClear="true" optionLabel="name"
                              optionValue="id"/>
                    <small v-if="v$.form.equipamento.eficazEpi.$invalid && submitted"
                           class="p-error">{{ v$.form.equipamento.eficazEpi.required.$message }}</small>
                </div>

                <FormAgenteEpi :epiCreate="epiCreate" :epiRemove="epiRemove" :epiUpdate="epiUpdate"
                               :epis="form.equipamento.epis"/>

                <Divider align="left" type="dashed">
                    <b>Requisitos da norma regulamentadora</b>
                </Divider>
                <div class="field col-4 mb-0">
                    <label for="equipamentoRequisitoProtecao">
                        Tentou utilizar?
                        <span
                            v-tooltip="'Foi tentada a implementação de medidas de proteção coletiva, de caráter administrativo ou de organização, optando-se pelo EPI por inviabilidade técnica, insuficiência ou interinidade, ou ainda em caráter complementar ou emergencial?'"
                            class="pi pi-question-circle"></span>
                    </label>
                    <Dropdown id="equipamentoRequisitoProtecao" v-model="form.equipamento.requisito.protecao"
                              :options="selectSimNao" :showClear="true" optionLabel="name" optionValue="id"/>
                </div>
                <div class="field col-4 mb-0">
                    <label for="equipamentoRequisitoCondicao">
                        Observado condições?
                        <span
                            v-tooltip="'Foram observadas as condições de funcionamento do EPI ao longo do tempo, conforme especificação técnica do fabricante nacional ou importador, ajustadas às condições de campo?'"
                            class="pi pi-question-circle"></span>
                    </label>
                    <Dropdown id="equipamentoRequisitoCondicao" v-model="form.equipamento.requisito.condicao"
                              :options="selectSimNao" :showClear="true" optionLabel="name" optionValue="id"/>
                </div>
                <div class="field col-4 mb-0">
                    <label for="equipamentoRequisitoUso">
                        Observado uso?
                        <span
                            v-tooltip="'Foi observado o uso ininterrupto do EPI ao longo do tempo, conforme especificação técnica do fabricante nacional ou importador, ajustadas às condições de campo?'"
                            class="pi pi-question-circle"></span>
                    </label>
                    <Dropdown id="equipamentoRequisitoUso" v-model="form.equipamento.requisito.uso"
                              :options="selectSimNao" :showClear="true" optionLabel="name" optionValue="id"/>
                </div>
                <div class="field col-4 mb-0">
                    <label for="equipamentoRequisitoValidade">
                        Observado validade?
                        <span v-tooltip="'Foi observado o prazo de validade do CA no momento da compra do EPI?'"
                              class="pi pi-question-circle"></span>
                    </label>
                    <Dropdown id="equipamentoRequisitoValidade" v-model="form.equipamento.requisito.validade"
                              :options="selectSimNao" :showClear="true" optionLabel="name" optionValue="id"/>
                </div>
                <div class="field col-4 mb-0">
                    <label for="equipamentoRequisitoTroca">
                        Observado troca?
                        <span
                            v-tooltip="'É observada a periodicidade de troca definida pelo fabricante nacional ou importador e/ou programas ambientais, comprovada mediante recibo assinado pelo usuário em época própria?'"
                            class="pi pi-question-circle"></span>
                    </label>
                    <Dropdown id="equipamentoRequisitoTroca" v-model="form.equipamento.requisito.troca"
                              :options="selectSimNao" :showClear="true" optionLabel="name" optionValue="id"/>
                </div>
                <div class="field col-4 mb-0">
                    <label for="equipamentoRequisitoHigienizacao">
                        Observado higienização?
                        <span
                            v-tooltip="'É observada a higienização conforme orientação do fabricante nacional ou importador?'"
                            class="pi pi-question-circle"></span>
                    </label>
                    <Dropdown id="equipamentoRequisitoHigienizacao" v-model="form.equipamento.requisito.higienizacao"
                              :options="selectSimNao" :showClear="true" optionLabel="name" optionValue="id"/>
                </div>
            </div>
        </form>
        <template #footer>
            <Button class="p-button-text" icon="pi pi-times" label="Cancelar" @click="toggleModal"/>
            <Button :label="isEdit ? 'Atualizar' : 'Adicionar'" icon="pi pi-check" @click="save"/>
        </template>
    </Dialog>

    <Divider align="left" type="dashed">
        <b>Agente(s) nocivo(s) ao(s) qual(is) o trabalhador está exposto</b>
    </Divider>
    <div class="field col-12 m-0">
        <Button class="p-button-outlined w-14rem" icon="pi pi-plus" label="Adicionar agente"
                @click="toggleModal"></Button>
    </div>

    <DataTable v-if="agentes.length" :value="agentes" class="w-full border-1 border-gray-100">
        <Column :style="{'width':'31%'}" header="Agente nocivo">
            <template #body="slotProps">
                {{ this.agenteCodigos.find(element => element.id === slotProps.data.codigo)?.name }}
            </template>
        </Column>
        <Column bodyClass="text-right" class="p-2">
            <template #body="slotProps">
                <div class="inline-flex">
                    <Button class="p-button-text p-button-secondary inline-flex mr-2" icon="pi pi-pencil"
                            title="Editar" @click="edit(slotProps.index, slotProps.data)"/>
                    <Button class="p-button-text p-button-secondary" icon="pi pi-times" title="Excluir"
                            @click="remove(slotProps.index)"/>
                </div>
            </template>
        </Column>
    </DataTable>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { helpers, required, requiredIf } from '@vuelidate/validators';

import tabela24 from '../../_tabelas-esocial/tabela-24.json';
import FormAgenteEpi from './FormAgenteEpi';
import DropdownUnidadesMedida from '../../../../riscos/components/DropdownUnidadesMedida.vue';

export default {
    components: { FormAgenteEpi, DropdownUnidadesMedida },
    setup: () => ({ v$: useVuelidate() }),
    props: {
        agentes: Array,
        removeEmptyValues: Function,
        agenteCreate: Function,
        agenteRemove: Function,
        agenteUpdate: Function
    },
    data() {
        return {
            recordIndex: undefined,
            showModal: false,
            form: {},
            unidadeMedida: null,
            submitted: false,
            selectSimNao: [
                { id: 'S', name: 'Sim' },
                { id: 'N', name: 'Não' }
            ],
            agenteCodigos: tabela24,
            agenteTipos: [
                { id: '1', name: 'Critério quantitativo' },
                { id: '2', name: 'Critério qualitativo' }
            ],
            equipamentoUtilizaEpc: [
                { id: '0', name: 'Não se aplica' },
                { id: '1', name: 'Não implementa' },
                { id: '2', name: 'Implementa' }
            ],
            equipamentoUtilizaEpi: [
                { id: '0', name: 'Não se aplica' },
                { id: '1', name: 'Não utilizado' },
                { id: '2', name: 'Utilizado' }
            ]
        };
    },
    computed: {
        isEdit() {
            return this.recordIndex >= 0;
        },
        isRequiredInfoEquipamentos() {
            return this.form.equipamento.epis.length > 0;
        }
    },
    validations() {
        return {
            form: {
                codigo: { required: helpers.withMessage('Tipo de agente deve ser informado', required) },
                equipamento: {
                    utilizaEpc: {
                        required: helpers.withMessage(
                            'Deve ser informado se é utilizado EPC',
                            requiredIf(() => this.form.equipamento.epis.length > 0)
                        )
                    },
                    eficazEpc: {
                        required: helpers.withMessage(
                            'Deve ser informado se os EPCs são eficazes',
                            requiredIf(() => this.isRequiredInfoEquipamentos)
                        )
                    },
                    utilizaEpi: {
                        required: helpers.withMessage(
                            'Deve ser informado se é utilizado EPI',
                            requiredIf(() => this.isRequiredInfoEquipamentos)
                        )
                    },
                    eficazEpi: {
                        required: helpers.withMessage(
                            'Deve ser informado se os EPIs são eficazes',
                            requiredIf(() => this.isRequiredInfoEquipamentos)
                        )
                    },
                }
            }
        };
    },
    watch: {
        unidadeMedida: function (newValue) {
            this.form.unidadeMedida = newValue.id;
        }
    },
    methods: {
        toggleModal() {
            this.showModal = !this.showModal;
            this.resetForm();
        },
        resetForm() {
            this.recordIndex = undefined;
            this.submitted = false;
            this.form = {
                equipamento: {
                    epis: [],
                    requisito: {}
                }
            };
        },
        epiCreate(epi) {
            this.form.equipamento.epis.push(epi);
        },
        epiRemove(index) {
            this.form.equipamento.epis.splice(index, 1);
        },
        epiUpdate(index, epi) {
            this.form.equipamento.epis.splice(index, 1, epi);
        },
        edit(index, data) {
            this.toggleModal();
            this.recordIndex = index;
            this.form = Object.assign({}, data);

            if (!this.form.equipamento) {
                this.form.equipamento = {
                    epis: [],
                    requisito: {}
                };
            }
            if (!this.form.equipamento.epis) {
                this.form.equipamento.epis = [];
            }
            if (!this.form.equipamento.requisito) {
                this.form.equipamento.requisito = {};
            }
        },
        remove(index) {
            this.agenteRemove(index);
        },
        async save() {
            this.submitted = true;

            const result = await this.v$.$validate();
            if (!result) {
                return;
            }

            const agente = this.removeEmptyValues(JSON.parse(JSON.stringify(this.form)));
            this.isEdit
                ? this.agenteUpdate(this.recordIndex, agente)
                : this.agenteCreate(agente);

            this.toggleModal();
        }
    }
};
</script>
