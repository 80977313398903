<template>
    <Dialog :draggable="false" :modal="true" :style="{width: '600px'}"
            :visible="showModal" header="Cadastrando responsável" position="top">
        <form id="formResponsavel" ref="form">
            <div class="p-fluid grid">
                <div class="field col-4 m-0">
                    <label :class="{'p-error':v$.form.cpf.$invalid && submitted}" for="cpf">
                        CPF <span class="p-error">*</span>
                    </label>
                    <InputMask id="cpf" v-model="form.cpf"
                               :class="{'p-invalid':v$.form.cpf.$invalid && submitted}" :unmask="true"
                               mask="999.999.999-99"/>
                    <small v-if="(v$.form.cpf.$invalid && submitted)" class="p-error">{{
                            v$.form.cpf.required.$message
                        }}</small>
                </div>
                <div class="field col-8 m-0">
                    <label for="orgao">Órgão responsável</label>
                    <Dropdown id="orgao" v-model="form.orgao" :options="orgaos" :showClear="true" optionLabel="name"
                              optionValue="id"/>
                </div>
                <div class="field col-4 m-0">
                    <label for="orgaoDescricao">Descrição do órgão</label>
                    <InputText id="orgaoDescricao" v-model="form.orgaoDescricao"/>
                </div>
                <div class="field col-4 m-0">
                    <label for="orgaoInscricao">Inscrição do órgão</label>
                    <InputText id="orgaoInscricao" v-model="form.orgaoInscricao"/>
                </div>
                <div class="field col-4 m-0">
                    <label for="orgaoEstado">UF do órgão</label>
                    <DropdownEsocialEstado id="orgaoEstado" v-model="orgaoEstado" :idValue="form.orgaoEstado"
                                           optionLabel="nome"/>
                </div>
            </div>
        </form>
        <template #footer>
            <Button class="p-button-text" icon="pi pi-times" label="Cancelar" @click="toggleModal"/>
            <Button :label="isEdit ? 'Atualizar' : 'Adicionar'" icon="pi pi-check" @click="save"/>
        </template>
    </Dialog>

    <Divider align="left" type="dashed">
        <b>Informações relativas ao responsável pelos registros ambientais</b>
    </Divider>
    <div class="field col-12 m-0">
        <Button class="p-button-outlined w-14rem" icon="pi pi-plus" label="Adicionar responsável"
                @click="toggleModal"></Button>
    </div>

    <DataTable v-if="responsaveis.length" :value="responsaveis" class="w-full border-1 border-gray-100">
        <Column :style="{width: '20%'}" field="cpf" header="CPF"></Column>
        <Column :style="{width: '80%'}" header="Órgão responsável">
            <template #body="slotProps">
                {{ this.orgaos.find(element => element.id === slotProps.data.orgao)?.name }}
            </template>
        </Column>
        <Column bodyClass="text-right" class="p-2">
            <template #body="slotProps">
                <div class="inline-flex">
                    <Button class="p-button-text p-button-secondary inline-flex mr-2" icon="pi pi-pencil"
                            title="Editar" @click="edit(slotProps.index, slotProps.data)"/>
                    <Button class="p-button-text p-button-secondary" icon="pi pi-times" title="Excluir"
                            @click="remove(slotProps.index)"/>
                </div>
            </template>
        </Column>
    </DataTable>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import DropdownEsocialEstado from "../_shared/DropdownEsocialEstado.vue";

export default {
    setup: () => ({v$: useVuelidate()}),
    components: {DropdownEsocialEstado},
    props: {
        responsaveis: Array,
        responsavelCreate: Function,
        responsavelRemove: Function,
        responsavelUpdate: Function
    },
    data() {
        return {
            recordIndex: undefined,
            showModal: false,
            form: {},
            orgaos: [
                {id: '1', name: 'Conselho Regional de Medicina - CRM'},
                {id: '4', name: 'Conselho Regional de Engenharia e Agronomia - CREA'},
                {id: '9', name: 'Outros'}
            ],
            orgaoEstado: undefined
        };
    },
    validations() {
        return {
            form: {
                cpf: {required: helpers.withMessage('CPF deve ser informado', required)}
            }
        };
    },
    computed: {
        isEdit() {
            return this.recordIndex >= 0;
        }
    },
    watch: {
        orgaoEstado: function (newValue) {
            this.form.orgaoEstado = newValue ? newValue.sigla : null;
        }
    },
    methods: {
        toggleModal() {
            this.showModal = !this.showModal;
            this.resetForm();
        },
        resetForm() {
            this.recordIndex = undefined;
            this.submitted = false;
            this.form = {};
        },
        edit(index, data) {
            this.toggleModal();
            this.recordIndex = index;
            this.form = Object.assign({}, data);
        },
        remove(index) {
            this.responsavelRemove(index);
        },
        async save() {
            this.submitted = true;

            const result = await this.v$.$validate();
            if (!result) {
                return;
            }

            const responsavel = Object.assign({}, this.form);
            this.isEdit
                ? this.responsavelUpdate(this.recordIndex, responsavel)
                : this.responsavelCreate(responsavel);

            this.toggleModal();
        }
    }
};
</script>
