<template>
    <AppOnePageDropdown
        ref="dropdown"
        v-model="value"
        :autoLoad="autoLoad"
        :idValue="idValue"
        :itemSize="itemSize"
        :renderOption="customOption"
        :service="$service"
        optionLabel="name"
        placeholder="Selecione o funcionário..."
    >
    </AppOnePageDropdown>
</template>
<script>
import BaseService from '@/services/BaseService';

export default {
    props: {
        autoLoad: {
            type: Boolean,
            default: true
        },
        modelValue: {
            type: Object
        },
        itemSize: {
            type: Number,
            default: 20
        },
        idValue: {
            type: Number
        },
        customerId: {
            type: Number
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            $service: null
        };
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    created() {
        this.$service = new BaseService('/customer/employees');
    },
    watch: {
        customerId() {
            if (!this.customerId) return;
            setTimeout(() => {
                this.$refs.dropdown.load({
                    itemSize: this.itemSize,
                    filtrosExtras: {customerId: this.customerId}
                });
            }, 100);
        }
    },
    methods: {
        customOption(slotProps) {
            return (slotProps.option?.matriculaEsocial ? slotProps.option?.matriculaEsocial + ' - ' : '') +
                slotProps.option?.name +
                (slotProps.option?.situation ? ' - ' + slotProps.option?.situation : '');
        }
    }
};
</script>
<style lang="">
</style>
